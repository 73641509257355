import { css } from "@emotion/react";
import theme from "src/theme";

const sharedMenuItemStyle = `
 /* padding: 0 !important;
  height: 56px !important;
  margin-top: 0 !important;
  display: flex;
  flex-direction: row;*/
`;

export default {
  dashboardMenuItem: css`
    ${sharedMenuItemStyle}
    margin-bottom: 0.5rem !important;
    background-color: ${theme.colors.primary.blue} !important;
    :hover {
      background-color: ${theme.colors.primary.blue} !important;
    }
  `,
  menu: css`
    background: transparent;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
  `,
  menuItem: css`
    ${sharedMenuItemStyle}
    background-color: ${theme.colors.black} !important;
    margin-bottom: 0 !important;
    margin: 0 !important;
  `,
  profileMenuItem: css`
    background-color: ${theme.colors.black} !important;
    /* position: absolute !important;*/
    bottom: 0 !important;
    padding: 0 !important;
    height: 56px !important;
    margin: 0 !important;
    /*width: 56px;*/
    :hover {
      .iconFill {
        fill: ${theme.colors.secondary.cyan} !important;
      }
      .iconStroke {
        stroke: ${theme.colors.secondary.cyan} !important;
      }
    }
  `,
  logout: css`
    font-size: 2rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    :active {
      background-color: transparent;
    }

    button {
      background-color: transparent;
      cursor: pointer;
      border: 0;
      padding-right: 0;

      :focus {
        outline: 0;
      }
    }
  `,
  navLink: css`
    color: ${theme.colors.white} !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;

    .dashboardIcon,
    .dashboardIconInactive,
    .iconFill {
      fill: ${theme.colors.white};
    }
    .iconStroke {
      stroke: ${theme.colors.white};
      stroke-width: 1.5;
    }

    &.active {
      font-weight: 600;
    }

    :hover,
    &.active {
      color: ${theme.colors.functional.red} !important;
      text-decoration: none !important;

      .iconFill {
        fill: ${theme.colors.functional.red} !important;
      }
      .iconStroke {
        stroke: ${theme.colors.functional.red} !important;
      }
      .dashboardIcon,
      .dashboardIconInactive {
        fill: ${theme.colors.functional.red} !important;
      }
    }

    .iconFillSelected {
      fill: ${theme.colors.functional.red} !important;
    }

    .iconStrokeSelected {
      stroke: ${theme.colors.secondary.cyan} !important;
    }
  `,
  tooltip: css`
    /*position: absolute;*/
    position: relative;
    height: 56px;
  `,
};
