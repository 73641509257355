import { css } from "@emotion/react";
import theme from "src/theme";
import { darken } from "polished";
import { queries } from "../../../../utils/media-queries";

export default {
  cover: css`
    object-fit: cover;
  `,
  fill: css`
    object-fit: fill;
  `,
  contain: css`
    object-fit: contain;
  `,
  darkOverlay: css`
    background: ${theme.colors.black};
  `,
  darkOverlayImage: css`
    opacity: 0.6;
  `,
  overlayImageLargeText: css`
    font-size: 52px;
    line-height: 58px;
  `,
  centerDLCoverTop: css`
    object-position: center top;

    @media ${queries.large} {
      object-position: center -250px;
    }
  `,
  centerTwoRoutes: css`
    object-position: center bottom;

    @media ${queries.small} {
      object-position: center -320px;
    }
    @media ${queries.medium} {
      object-position: center -500px;
    }
    @media ${queries.large} {
      object-position: center -750px;
    }
    @media ${queries.extraLarge} {
      object-position: center -850px;
    }
    @media ${queries.xxLarge} {
      object-position: center -1050px;
    }
  `,
  containLogoImage: css`
    width: 100%;
    /*height: 150px;*/
    object-fit: contain;
    /*margin-top: 1rem;*/
    border-radius: 8px 8px 0 0;
  `,
  containLogoImageBottom: css`
    width: 100%;
    /*height: 150px;*/
    object-fit: contain;
    /*margin-bottom: 1rem;*/
    border-radius: 0 0 8px 8px;
  `,
  card: css`
    max-width: 370px;
    /*margin-right: 20px !important;*/
    border: none;

    .ant-card-body {
      padding: 0 1.5rem 1.5rem 1.5rem !important;
    }

    .ant-card-head {
      padding: 1.5rem 1.5rem 1rem 1.5rem !important;
    }
  `,
  cardText: css`
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  `,
  center: css`
    text-align: center;
  `,
  donateButtonWrapper: css`
    padding: 40px 0;
  `,
  donateButton: css`
    background: #cdab85;
    color: #000;
    border: 1px solid #bb8d5a;
    border-radius: 8px;
    display: inline-block;
    font-size: 28px;
    font-weight: 600;
    line-height: 30px;
    padding: 12px 24px;
    text-decoration: none;
    text-shadow: none;

    :hover {
      text-decoration: none;
      background: ${darken(0.05, "#cdab85")};
    }
  `,
  contentTitle: css`
    font-weight: 600;
    padding: 0 0 20px 0;
    font-size: 22px;
  `,
  contentTitleHeading: css`
    font-weight: 600;
    padding: 20px 0 40px 0;
    font-size: 32px;
    line-height: 34px;
  `,
  contentSubTitle: css`
    font-weight: 600;
    padding: 0 0 20px 0;
    font-size: 18px;
  `,
  contentWrapper: css`
    display: flex;
    justify-content: center;
  `,
  contentBox: css`
    max-width: 560px;
    min-width: 0;
    flex-shrink: 1;
    flex-grow: 1;
  `,
  contentYoutubeWrapper: css`
    width: 100%;
  `,
  contentYoutubeBox: css`
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  `,
  contentYoutube: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  `,
  pageContent2Wrapper: css`
    margin-top: 0;

    .page-content-text-section {
      padding-top: 0;
    }
  `,
};
