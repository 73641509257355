/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from "react";

export interface IWindowCoordinates {
  x: number;
  y: number;
}

export const scrollToCordinates = (coordinates: IWindowCoordinates) => {
  try {
    window.scroll({
      behavior: "auto",
      left: coordinates.x,
      top: coordinates.y,
    });
  } catch (error) {
    window.scrollTo(coordinates.x, coordinates.y);
  }
};

export default function useScrollToTop(history: any): void {
  useEffect(() => {
    if (!history) {
      return () => {};
    }

    const getScrollToCoordinates = (): IWindowCoordinates => {
      const scrollCoordinates: IWindowCoordinates = {
        x: 0,
        y: 0,
      };
      const hash = history.location.hash.slice(1);

      if (hash) {
        const hashElement = document.getElementById(decodeURI(hash));

        if (hashElement) {
          const hashElementBoundingRect = hashElement.getBoundingClientRect();
          scrollCoordinates.y =
            hashElementBoundingRect.top + window.pageYOffset;
        }
      }

      return scrollCoordinates;
    };

    const unlisten = history.listen(() => {
      const scrollCoordinates = getScrollToCoordinates();

      if (history.action !== "POP") {
        scrollToCordinates(scrollCoordinates);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return unlisten;
  }, [history]);
}
