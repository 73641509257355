import React, { ReactElement } from "react";
import theme from "src/theme";
import IComponentIconProps from "../../interfaces";

const Dashboard = ({
  height = theme.svgSize.nav,
  width = theme.svgSize.nav,
  minx = 0,
  miny = 0,
  isActive = true,
}: IComponentIconProps): ReactElement => {
  const fillClasses = isActive ? "dashboardIcon" : "dashboardIconInactive";
  const viewBox = `${String(minx)} ${String(miny)} ${String(width)} ${String(
    height
  )}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      aria-labelledby="dashboard-nav-link"
    >
      <title id="dashboard-nav-link" />
      <g>
        <path
          className={fillClasses}
          fillRule="nonzero"
          d="M.89355002 12.6798003C.400055971 12.6798003-2.31296463e-14 12.2797443-2.31296463e-14 11.7862503L-2.31296463e-14 7.95480018C.0231009955 7.47870097.41584069 7.1045555.89250002 7.1045555 1.36915935 7.1045555 1.76189905 7.47870097 1.78500004 7.95480018L1.78500004 11.7862503C1.78500004 12.2789254 1.38622377 12.6786424.89355002 12.6798003zM17.9518504 12.6798003C17.4583564 12.6798003 17.0583004 12.2797443 17.0583004 11.7862503L17.0583004 7.95480018C17.0423277 7.62561179 17.2088576 7.31435778 17.4915782 7.14497895 17.7742988 6.97560011 18.127302 6.97560011 18.4100226 7.14497895 18.6927432 7.31435778 18.8592731 7.62561179 18.8433004 7.95480018L18.8433004 11.7862503C18.8433018 12.2789254 18.4445242 12.6786424 17.9518504 12.6798003zM5.16600012 16.9050009C4.89513828 16.905279 4.63527484 16.7978749 4.44364806 16.606445 4.25202127 16.4150152 4.14435009 16.1552623 4.14435009 15.8844004L4.14435009 3.85980009C4.16202786 3.30859094 4.61398257 2.87089845 5.16547512 2.87089845 5.71696767 2.87089845 6.16892237 3.30859094 6.1866023 3.85980009L6.1866023 15.8823004C6.18715784 16.153344 6.07987691 16.4134774 5.88841679 16.6053315 5.69695668 16.7971855 5.43704431 16.9050009 5.16600012 16.9050009zM13.6815003 16.9050009C13.1178385 16.9050009 12.6609003 16.4480622 12.6609003 15.8844004L12.6609003 3.85980009C12.6785781 3.30859094 13.1305328 2.87089845 13.6820253 2.87089845 14.2335179 2.87089845 14.6854726 3.30859094 14.7031525 3.85980009L14.7031525 15.8823004C14.7037086 16.153526 14.5962836 16.413819 14.4045962 16.6057033 14.2129088 16.7975877 13.9527264 16.90528 13.6815003 16.9050009L13.6815003 16.9050009zM9.42060021 19.7400004C8.77193318 19.7394215 8.2462291 19.2137175 8.24565019 18.5650504L8.24565019 1.17495003C8.24565019.526043045 8.77169323 5.92118946e-15 9.42060021 5.92118946e-15 10.0695072 5.92118946e-15 10.5955502.526043045 10.5955502 1.17495003L10.5955502 18.5671504C10.5938152 19.2149972 10.0684491 19.7394243 9.42060021 19.7400004z"
          transform="translate(18 18)"
        />
      </g>
    </svg>
  );
};

export default Dashboard;
