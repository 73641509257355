import useScrollToTop from "src/utils/use-scroll-to-top";
import { useHistory } from "react-router-dom";

const ScrollToTop: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const history = useHistory();

  useScrollToTop(history);

  return null;
};

export default ScrollToTop;
