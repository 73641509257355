import React, { Fragment, ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import { useLocation, NavLink } from "react-router-dom";
import { DashboardIcon } from "src/app/assets/icons";
import TopNavigationStyles from "src/components/top-navigation/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Image from "src/components/image";
import DarkUniversesLogoTextImage from "src/app/assets/images/dark-universes-logo-text.png";
import styles from "./styles";

// import { Layout, Menu, Tooltip } from "antd";
// import { Menu, Tooltip } from "antd";
// import { DashboardIcon } from "src/app/assets/icons";
// import { INavProp } from "src/components/navigation";

export interface IFooterProp {
  key: string;
  to: string;
  isExternal?: boolean;
  dataTestId: string;
  title: string;
  icon: (isActive: boolean) => ReactNode;
  visible: boolean;
  customStyles: SerializedStyles;
}

export interface IFooterHeadingProp {
  key: string;
  title: string;
  items: Array<IFooterProp>;
}

const dashboardIcon = (title: string) => (isActive: boolean) =>
  (
    <DashboardIcon
      isActive={isActive}
      title={title}
      minx="16"
      miny="7"
      width="32"
      height="40"
    />
  );

const faIcon = (prop: IconProp) => () =>
  <FontAwesomeIcon css={styles.navLinkFAIcon} icon={prop} fixedWidth />;

const FooterContent = (): ReactElement => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const footerHeading: Array<IFooterHeadingProp> = [
    {
      key: "/quick-links-footer-heading",
      title: "Quick Links",
      items: [
        {
          key: "/home-footer",
          to: "/",
          dataTestId: "home-footer-link",
          title: "Home",
          icon: dashboardIcon("Home"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
      ],
    },
    {
      key: "/dark-universes-footer-heading",
      title: "Dark Universes",
      items: [
        {
          key: "/dark-realms-footer",
          to: "/dark-realms",
          dataTestId: "dark-realms-footer-link",
          title: "Dark Realms",
          icon: dashboardIcon("Dark Realms"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/dark-legacies-footer",
          to: "/dark-legacies",
          dataTestId: "dark-legacies-footer-link",
          title: "Dark Legacies",
          icon: dashboardIcon("Dark Legacies"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/dark-legends-footer",
          to: "/dark-legends",
          dataTestId: "dark-legends-footer-link",
          title: "Dark Legends",
          icon: dashboardIcon("Dark Legends"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/corporation-wars-footer",
          to: "/corporation-wars",
          dataTestId: "corporation-wars-footer-link",
          title: "Corporation Wars",
          icon: dashboardIcon("Dark Legacies: Corporation Wars"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
      ],
    },
    {
      key: "/dark-companions-footer-heading",
      title: "Dark Companions",
      items: [
        {
          key: "/stokerverse-footer",
          to: "/stokerverse",
          dataTestId: "stokerverse-footer-link",
          title: "StokerVerse",
          icon: dashboardIcon("StokerVerse"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/shadow-writer-footer",
          to: "https://www.shadow-writer.co.uk/",
          isExternal: true,
          dataTestId: "shadow-writer-footer-link",
          title: "Shadow Writer",
          icon: dashboardIcon("Shadow Writer - Paul Kane"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/peter-darvill-evans-footer",
          to: "https://peterdarvillevans.com//",
          isExternal: true,
          dataTestId: "peter-darvill-evans-footer-link",
          title: "Peter Darvill-Evans",
          icon: dashboardIcon("Peter Darvill-Evans"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
      ],
    },
    {
      key: "/social-media-footer-heading",
      title: "Social Media",
      items: [
        {
          key: "/du-facebook-footer",
          to: "https://www.facebook.com/DarkUniverses",
          isExternal: true,
          dataTestId: "du-facebook-footer-link",
          title: "Dark Universes",
          icon: faIcon(faFacebookF),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/sv-facebook-footer",
          to: "https://www.facebook.com/stokerverse",
          isExternal: true,
          dataTestId: "sv-facebook-footer-link",
          title: "StokerVerse",
          icon: faIcon(faFacebookF),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/dl-youtube-footer",
          to: "https://www.youtube.com/@ClaudiaChristiansDarkLegacies",
          isExternal: true,
          dataTestId: "youtube-footer-link",
          title: "Dark Legacies",
          icon: faIcon(faYoutube),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/sv-youtube-footer",
          to: "https://www.youtube.com/channel/UCVsqT53wFhNIrVdXTbnU2bA",
          isExternal: true,
          dataTestId: "youtube-footer-link",
          title: "StokerVerse",
          icon: faIcon(faYoutube),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/twitter-footer",
          to: "https://twitter.com/StokerMcAuley",
          isExternal: true,
          dataTestId: "twitter-footer-link",
          title: "Twitter",
          icon: faIcon(faTwitter),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
      ],
    },
    {
      key: "/resources-footer-heading",
      title: "Resources",
      items: [
        {
          key: "/c-three-foundation-footer",
          to: "/c-three-foundation",
          dataTestId: "c-three-foundation-footer-link",
          title: "Official Charity",
          icon: dashboardIcon("C Three Foundation"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/c-three-foundation-external-footer",
          to: "https://cthreefoundation.org/",
          isExternal: true,
          dataTestId: "c-three-foundation-external-footer-link",
          title: "C Three Foundation",
          icon: dashboardIcon("C Three Foundation"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/hwa-external-footer",
          to: "https://horror.org/",
          isExternal: true,
          dataTestId: "hwa-external-footer-link",
          title: "Horror Writers Association",
          icon: dashboardIcon("Horror Writers Association"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
        {
          key: "/b5-events-external-footer",
          to: "https://b5events.com/",
          isExternal: true,
          dataTestId: "b5-events-external-footer-link",
          title: "B5 Events",
          icon: dashboardIcon("B5 Events"),
          visible: true,
          customStyles: TopNavigationStyles.menuItem,
        },
      ],
    },
  ];

  const duLogo = Image("Dark Universes", DarkUniversesLogoTextImage, [
    styles.duLogoImage,
  ]);

  return (
    <div css={styles.footer}>
      <div css={[styles.footerSection, styles.footerLogoSection]}>
        {duLogo}
        {/* <div css={styles.footerLogoHeading}>Dark</div>
        <div css={styles.footerLogoHeading}>Universes</div> */}
        <div css={[styles.footerText]}>
          <span css={[styles.footerLogoTextCopyright]}>
            &copy; 2020-{currentYear}
          </span>
          <span css={[styles.footerLogoTextARR]}>All Rights Reserved</span>
        </div>
      </div>
      {footerHeading.map(
        ({
          key: headingkey,
          title,
          items,
        }: IFooterHeadingProp): ReactElement => {
          return (
            <div key={headingkey} css={styles.footerSection}>
              <div css={styles.footerHeading}>{title}</div>
              {items.map(
                ({
                  visible,
                  icon,
                  key,
                  to,
                  isExternal = false,
                  dataTestId,
                  title: translation,
                  customStyles,
                }: IFooterProp): ReactElement => {
                  const isLinkActive = to === window.location.pathname;

                  return visible ? (
                    <div css={[styles.footerItem, customStyles]} key={key}>
                      {isExternal ? (
                        <a
                          css={styles.navLink}
                          href={to}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {icon(isLinkActive)}
                          {translation}
                        </a>
                      ) : (
                        <NavLink
                          css={styles.navLink}
                          to={to}
                          data-testid={dataTestId}
                        >
                          {icon(isLinkActive)}
                          {translation}
                        </NavLink>
                      )}
                    </div>
                  ) : (
                    <Fragment key={key} />
                  );
                }
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default FooterContent;
