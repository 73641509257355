import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "../../../utils/media-queries";

export default {
  pageContentWrapper: css`
    margin-top: 20px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    flex-grow: 1;
  `,
  pageContent: css`
    width: 1280px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: ${theme.colors.white};
  `,
  pageContentTextSection: css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 300px;
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 24px;

    @media ${queries.small} {
      width: 400px;
    }
  `,
  pageContentSideSection: css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 300px;
    min-width: 0;
    max-width: 400px;
    flex-grow: 1;
    flex-shrink: 1;

    @media ${queries.small} {
      width: 400px;
    }
  `,
  pageContentHeading: css`
    font-weight: 600;
    /*color: ${theme.colors.white};*/
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 30px;
  `,
  pageContentBlock: css`
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  pageContentBlockHeading: css`
    font-weight: 600;
    padding: 0 0 20px 0;
    font-size: 22px;
  `,
  pageContentBlockHeadingSmall: css`
    font-weight: 600;
    padding: 0 0 20px 0;
    font-size: 18px;
  `,
  pageContentBlockQuote: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
  `,
  pageContentBlockInlineQuote: css`
    font-weight: 600;
    font-size: 16px;
    display: inline-block;
  `,
  pageContentBlockInlineEmphasis: css`
    font-weight: 600;
    font-size: 16px;
    display: inline-block;
  `,
  pageContentBlockQuoteBy: css`
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    display: inline-block;
    white-space: pre;
  `,
  pageContentItalic: css`
    font-style: italic;
  `,
  pageContentBlockContent: css`
    font-size: 16px;
    padding: 0 0 20px 0;
  `,
};
