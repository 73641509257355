import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "../../../../utils/media-queries";

export default {
  cover: css`
    object-fit: cover;
  `,
  fill: css`
    object-fit: fill;
  `,
  contain: css`
    object-fit: contain;
  `,
  clownPuppet: css`
    object-position: -350px center;

    @media ${queries.medium} {
      object-position: center center;
    }
  `,
  forestBirdWoman: css`
    object-position: center center;

    @media ${queries.small} {
      object-position: center center;
    }
    @media ${queries.medium} {
      object-position: center -10px;
    }
    @media ${queries.large} {
      object-position: center -30px;
    }
    @media ${queries.extraLarge} {
      object-position: center -40px;
    }
    @media ${queries.xxLarge} {
      object-position: center -70px;
    }
    @media ${queries.xxxLarge} {
      object-position: center -80px;
    }
  `,
  girlFearForest: css`
    object-position: -80px center;

    @media ${queries.small} {
      object-position: center center;
    }
  `,
  darkClown: css`
    object-position: 84% center;

    @media ${queries.extraSmall} {
      object-position: 86% center;
    }
    @media ${queries.small} {
      object-position: right center;
    }
  `,
  skullTime: css`
    object-position: center center;

    @media ${queries.medium} {
      object-position: center 80%;
    }
    @media ${queries.large} {
      object-position: center 70%;
    }
    @media ${queries.xxLarge} {
      object-position: center 65%;
    }
    @media ${queries.xxxLarge} {
      object-position: center 62%;
    }
  `,
  camouflageClown: css`
    object-position: right center;
  `,
  darkOverlay: css`
    background: ${theme.colors.black};
  `,
  darkOverlayImage: css`
    opacity: 1;
  `,
  darkOverlayText: css`
    display: none;
    top: 48%;

    @media ${queries.large} {
      display: inline-block;
      font-size: 75px;
      line-height: 78px;
      left: 28%;
    }
    @media ${queries.extraLarge} {
      font-size: 95px;
      line-height: 98px;
      left: 30%;
    }
    @media ${queries.xxLarge} {
      font-size: 100px;
      line-height: 104px;
      left: 28%;
    }
    @media ${queries.xxxLarge} {
      left: 29%;
    }
  `,
  containLogoImage: css`
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-top: 1rem;
  `,
  containLogoImageBottom: css`
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-bottom: 1rem;
  `,
};
