const config = {
  production: {
    production: true,
    auth: {
      domain: "dark-universes.com",
    },
    basePathname: "/",
  },
  staging: {
    staging: true,
    auth: {
      domain: "dark-universes.com",
    },
    basePathname: "/",
  },
  development: {
    development: true,
    auth: {
      domain: "dark-universes.com",
    },
    basePathname: "/",
  },
};

export default config.development;
