import { SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";
import LazyLoad from "react-lazyload";
import VideoPlayer, { IVideoPlayerProp } from "src/components/video";

export interface ILazyVideoPlayerProp extends IVideoPlayerProp {
  wrapperStyle?: SerializedStyles | Array<SerializedStyles>;
  once?: boolean;
}

const LazyVideoPlayer = ({
  url,
  style,
  playing,
  light,
  wrapperStyle,
  once,
}: ILazyVideoPlayerProp): ReactNode => {
  return (
    <LazyLoad css={wrapperStyle} once={once}>
      {VideoPlayer({
        url,
        style,
        playing,
        light,
      })}
    </LazyLoad>
  );
};

export default LazyVideoPlayer;
