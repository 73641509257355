// import React, { Fragment, ReactElement, useState } from "react";
import React, { Fragment, ReactElement } from "react";
import { useLocation, NavLink } from "react-router-dom";
// import { Layout, Menu, Tooltip } from "antd";
// import { Menu, Tooltip } from "antd";
import { Menu } from "antd";
// import { DashboardIcon } from "src/app/assets/icons";
import { INavProp } from "src/components/navigation";
import styles from "./styles";

interface IProps {
  navLinks: Array<INavProp>;
}

const TopNavigation = ({ navLinks }: IProps): ReactElement => {
  // const { Sider } = Layout;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  // const pathname = (location ? location.pathname : "") as string;
  // const [selectedKey, setSelectedKey] = useState(
  //   (navLinks ?? []).find((item) => pathname == item.to).key
  // );

  return (
    <Menu
      theme="dark"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      defaultSelectedKeys={[location.pathname]}
      mode="horizontal"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      selectedKeys={[location.pathname]}
      css={styles.menu}
    >
      {navLinks.map(
        ({
          visible,
          icon,
          key,
          to,
          dataTestId,
          title: translation,
          customStyles,
        }: INavProp): ReactElement => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          // const isLinkActive = to === location.pathname;

          return visible ? (
            <Menu.Item css={customStyles} key={key}>
              <NavLink css={styles.navLink} to={to} data-testid={dataTestId}>
                {icon(false)}
                {translation}
              </NavLink>
            </Menu.Item>
          ) : (
            <Fragment key={key} />
          );
        }
      )}
    </Menu>
  );
};

export default TopNavigation;
