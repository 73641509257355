import React, { Fragment, ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import Image from "src/components/image";
import CarouselComponent, { ICarouselItem } from "src/components/carousel";
import PageContent, {
  IPageContentProp,
  QuoteContent,
} from "src/components/page-content";
import SocialBar from "src/components/social-bar";
import BatPotionImage from "src/app/assets/images/bat-potion.jpg";
import DarkForestImage from "src/app/assets/images/dark-forest.jpg";
import GirlFogCastleImage from "src/app/assets/images/girl-fog-castle.jpg";
import NightCastleCrowImage from "src/app/assets/images/night-castle-crow.jpg";
import ChrisDacreGraveyardImage from "src/app/assets/images/chris-dacre-graveyard.jpg";
// import HornedManImage from "src/app/assets/images/horror-horned-man.png";
// import BeakManImage from "src/app/assets/images/horror-beak-man.png";
import DraculaImage from "src/app/assets/images/horror-dracula.png";
import DacreStokerHeadshotImage from "src/app/assets/images/dacre-stoker-headshot.png";

import DraculasBedlamImage from "src/app/assets/images/draculas-bedlam.jpg";
import VirginsEmbraceImage from "src/app/assets/images/virgins-embrace.jpg";
import VoicesOfDraculaImage from "src/app/assets/images/voices-of-dracula.jpg";
import StokerVerseRPGQS1Image from "src/app/assets/images/stokerverse-rpg-qs1.jpg";
import StokerVerseRPGCoreImage from "src/app/assets/images/stokerverse-rpg-core.png";
import StokerVerseRPGTalesImage from "src/app/assets/images/stokerverse-rpg-tales.png";
import StokerVerseRPGCompanionImage from "src/app/assets/images/stokerverse-rpg-companion.png";
import StokerVerseRPGAuthorsPackImage from "src/app/assets/images/stokerverse-rpg-authors-pack.png";
import RenfieldVisionsOfMadnessImage from "src/app/assets/images/renfield-visions-of-madness.jpg";
import DraculaTheReturnIssue1Image from "src/app/assets/images/dracula-the-return-issue-1.jpg";
import AWhisperInTheDarknessImage from "src/app/assets/images/a-whisper-in-the-darkness.jpg";

import Head from "src/components/head";
import CardDeck, { ICardDeckItem } from "src/components/card-deck";
import LazyImage from "src/components/lazy-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { sortByProperty } from "src/utils/sort";
import styles from "./styles";

const faIcon = (prop: IconProp) => (
  <FontAwesomeIcon css={styles.cardDeckFAIcon} icon={prop} fixedWidth />
);

const buyNowIcon = (url: string) => (
  <a css={styles.cardDeckNavLink} href={url} target="_blank" rel="noreferrer">
    {faIcon(faCartArrowDown)}
    Buy Now
  </a>
);

const StokerVerse = (): ReactElement => {
  const carouselItems: Array<ICarouselItem> = [
    {
      key: "/chris-dacre-graveyard-carousel",
      content: Image("Chris McAuley & Dacre Stoker", ChrisDacreGraveyardImage, [
        styles.cover,
        styles.centerChrisDacre,
      ]),
    },
    {
      key: "/dracula-carousel",
      content: Image("Dracula", DraculaImage, [
        styles.cover,
        styles.centerTopDracula,
      ]),
    },
    {
      key: "/night-castle-crow-carousel",
      content: Image("StokerVerse", NightCastleCrowImage, [
        styles.cover,
        styles.centerNightCastle,
      ]),
    },
    {
      key: "/bat-poison-carousel",
      content: Image("StokerVerse", BatPotionImage, [
        styles.cover,
        styles.centerBatPotion,
      ]),
    },
    {
      key: "/dark-forest-carousel",
      content: Image("StokerVerse", DarkForestImage, [
        styles.cover,
        styles.centerDarkForest,
      ]),
    },
    {
      key: "/girl-fog-castle-carousel",
      content: Image("StokerVerse", GirlFogCastleImage, [styles.cover]),
    },
    /*
    {
      key: "/beak-man-carousel",
      content: Image("StokerVerse", BeakManImage, [
        styles.cover,
        styles.centerTopBeakMan,
      ]),
      customStyles: styles.carouselItem,
    },
    {
      key: "/horned-man-carousel",
      content: Image("StokerVerse", HornedManImage, [
        styles.cover,
        styles.centerTopHornedMan,
      ]),
      customStyles: styles.carouselItem,
    },
    */
  ];

  const cardDeckItems: Array<ICardDeckItem> = [
    {
      key: "/virgins-embrace-a-stokerVerse-graphic-novel-card",
      image: LazyImage(
        "Virgin's Embrace",
        VirginsEmbraceImage,
        styles.containLogoImage
      ),
      title: "Virgin's Embrace - A StokerVerse Graphic Novel",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            The Virgin’s Embrace – the first StokerVerse graphic novel released
            by AUK is receiving rave reviews.
          </div>
          <div css={styles.cardText}>
            &quot;A look at the original work against our contemporary views -
            as well the inspiration and happenings that brought it about. The
            retelling and graphic elements expand the story in a more
            appropriate modern form but still set within the period intended.
            Nice read and a good visual impact to it&quot; - William
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.co.uk/Virgins-Embrace-adaptation-originally-Stokerverse/dp/1789825504/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1651534353&sr=8-1"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      customImageStyles: styles.cardImage,
      displayOrder: 10,
    },
    {
      key: "/voices-of-dracula-stokerverse-audio-tales-card",
      image: LazyImage(
        "Voices of Dracula",
        VoicesOfDraculaImage,
        styles.containLogoImage
      ),
      title: "Voices of Dracula - StokerVerse Audio Tales",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Voices of Dracula - a 10-part series of short stories written by
            Chris McAuley and released by AUK Studios is now available on
            Audible.
          </div>
          <div css={styles.cardText}>
            120-years after the original release of Dracula, author Chris
            McAuley has teamed up with Dacre Stoker, great-grandnephew of Bram
            Stoker, to create a series of short story audio chapters set within
            the StokerVerse. Each story is narrated from the point of view of
            the characters we all know – or think we know – so well.
          </div>
          <div css={styles.cardText}>
            Narrated by some of the leading voice actors of today, these short
            stories are dark, twisted and of course hugely entertaining. Simon
            Templeman (Legacy of Kain, Dragon Age, Dead Space 3, Charmed and so
            much more) plays Dracula, with all the expertise and finesse one
            would expect from such a lauded performer, whilst Claudia Christian
            (Babylon 5, 9-1-1, Disney’s Atlantis: The Lost Empire) is the
            mysterious Thief. TV’s sixth Doctor, Colin Baker (Doctor Who,
            Kingdom, Hustle), masterfully narrates vampire hunter Van Helsing’s
            stories, whilst Terry Molloy - most famous for playing the Doctor’s
            on-screen nemesis Davros - takes the part of Renfield. Jessica
            Martin (Doctor Who, The Sandman) completes the line-up as Mina
            Harker.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.co.uk/Voices-Dracula-Short-StokerVerse-Stories/dp/B0992M27HB/ref=sr_1_1?crid=263C8U17FGR6&keywords=voices+of+dracula&qid=1651533987&sprefix=voices+of+dracula%2Caps%2C68&sr=8-1"
            )}
          </div>
        </Fragment>
      ),
      imageTop: false,
      customItemStyles: [styles.card, styles.cardRight],
      customImageStyles: styles.cardImage,
      displayOrder: 20,
    },
    {
      key: "/draculas-bedlam-a-stokerverse-novel-card",
      image: LazyImage(
        "Dracula’s Bedlam",
        DraculasBedlamImage,
        styles.containLogoImage
      ),
      title: "Dracula’s Bedlam - A StokerVerse Novel",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Dracula’s Bedlam – the second Stokerverse novel released by AUK is
            now available.
          </div>
          <div css={styles.cardText}>
            Is there a place more enthralling than that of the Asylum? The
            insane lurk in the shadows with gibbering mouths and twisted minds…
            Dr. Seward’s asylum is particularly interesting; a serial killer has
            his mind peeled back, a mysterious nurse walks the halls with a
            sweet smile and devious mind… and, of course, there’s the enigmatic
            Mr. Renfield...
          </div>
          <div css={styles.cardText}>
            Set between the cracks of the original Dracula novel, the
            StokerVerse series hopes to shed a little light into the dark areas
            which were not wholly explored by author Bram Stoker. Familiar
            figures from Dracula and original characters created specifically
            for the StokerVerse appear in these dark and twisted tales.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.co.uk/Draculas-Bedlam-2-Dacre-Stoker/dp/1789828538/ref=sr_1_1?crid=1YNIKSPEHN4ZC&keywords=dracula%27s+bedlam&qid=1651534603&sprefix=dracula+bedlam%2Caps%2C85&sr=8-1"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      customImageStyles: styles.cardImage,
      displayOrder: 30,
    },
    {
      key: "/renfield-visions-of-madness-comic-book-card",
      image: LazyImage(
        "Renfield: Visions of Madness",
        RenfieldVisionsOfMadnessImage,
        styles.containLogoImage
      ),
      title: "Renfield: Visions of Madness Comic Book",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Available from acclaimed indie comic book company Legacy Comix, this
            explores a prequel story involving Renfield. Written by Chris
            McAuley and illustrated by Steve Cange this is a terrifying and
            brutal narrative illustrated with an ‘art haus’ style.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.legacycomix.com/product/renfield-visions-of-madness-0-digital"
            )}
          </div>
        </Fragment>
      ),
      imageTop: false,
      customItemStyles: [styles.card, styles.cardRight],
      customImageStyles: styles.cardImage,
      displayOrder: 40,
    },
    {
      key: "/stokerverse-rpg-authors-pack-card",
      image: LazyImage(
        "StokerVerse RPG - Authors Pack",
        StokerVerseRPGAuthorsPackImage,
        styles.containLogoImage
      ),
      title: "StokerVerse RPG - Authors Pack",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            The StokerVerse RPG Author’s Pack is an eight-page booklet meant to
            accompany the GM Screen. This PDF contains useful information for
            running a game set in Victorian England as well as reference sheets
            for the StokerVerse’s S5S Game System.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.drivethrurpg.com/product/433205/StokerVerse-Roleplaying-Game-Authors-Pack?src=hottest_filtered"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      customImageStyles: styles.cardImage,
      displayOrder: 50,
    },
    {
      key: "/stokerverse-rpg-tales-card",
      image: LazyImage(
        "StokerVerse RPG - Tales from the StokerVerse",
        StokerVerseRPGTalesImage,
        styles.containLogoImage
      ),
      title: "StokerVerse RPG - Tales from the StokerVerse",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Tales from the StokerVerse contains two short stories perfect for
            introducing new elements of the Victorian-era and Gothic horror into
            your games. In addition to the short stories, the main antagonist of
            each is included as a fully-statted NPC.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.drivethrurpg.com/product/431569/StokerVerse-Roleplaying-Game-Tales-from-the-StokerVerse?src=hottest_filtered"
            )}
          </div>
        </Fragment>
      ),
      imageTop: false,
      customItemStyles: [styles.card, styles.cardRight],
      customImageStyles: styles.cardImage,
      displayOrder: 60,
    },
    {
      key: "/stokerverse-rpg-companion-card",
      image: LazyImage(
        "StokerVerse RPG - Companion",
        StokerVerseRPGCompanionImage,
        styles.containLogoImage
      ),
      title: "StokerVerse RPG - Companion",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Welcome to the StokerVerse RPG Author’s Companion. This
            comprehensive crib-sheet for StokerVerse authors is the Watson to
            your Holmes. Within, you will find details for various creatures and
            archetypes that comprise the strange dark world envisaged by Dacre
            Stoker and Chris McAuley, along with the reference sheets for the
            StokerVerse’s S5S Game System.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.drivethrurpg.com/product/431570/StokerVerse-Roleplaying-Game-Companion?src=hottest_filtered"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      customImageStyles: styles.cardImage,
      displayOrder: 70,
    },
    {
      key: "/stokerverse-rpg-core-card",
      image: LazyImage(
        "StokerVerse RPG",
        StokerVerseRPGCoreImage,
        styles.containLogoImage
      ),
      title: "StokerVerse RPG",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            In the StokerVerse Roleplaying Game you are drawn into a dark and
            twisted universe. Faced with terrors from the harsh realities of the
            Victorian period and the ever-present shadows of the era, you have
            the unenviable task of facing the Vampire courts, the Werewolf clans
            and the terrible denizens of the shadows. Save those you can, as you
            walk in the same gaslight as the Ripper, Hyde and Frankenstein’s
            Monster...can you stop them before they end you!
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.drivethrurpg.com/product/431567/StokerVerse-Roleplaying-Game?src=hottest_filtered"
            )}
          </div>
        </Fragment>
      ),
      imageTop: false,
      customItemStyles: [styles.card, styles.cardRight],
      customImageStyles: styles.cardImage,
      displayOrder: 80,
    },
    {
      key: "/stokerverse-rpg-quickstart-card",
      image: LazyImage(
        "StokerVerse RPG - Quickstart",
        StokerVerseRPGQS1Image,
        styles.containLogoImage
      ),
      title: "StokerVerse RPG - Quickstart",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Now available from Nightfall games. The 90-page quickstart RPG is a
            ‘pay what you like’ introduction to the first official role-playing
            sequel to the original Dracula novel. There are eight scenes to play
            through from the minds of Dacre Stoker, Chris McAuley and game
            writer Andrew Brown. Featuring stunning dark gothic artwork from
            legendary 2000AD artist Clint Langely. This is one adventure not to
            be missed.
          </div>
          <div css={styles.cardText}>
            &quot;Dracula is dead, of that we should be certain. The brave
            Texan, Quincey Morris, pierced his chest with a bowie knife. Morris
            paid with his life when he severed the great vampire’s ties to the
            mortal world. I can see by the puzzled look on your face that you
            may not be familiar with the full facts of this story. You may have
            read of a mysterious Romanian Count’s disappearance in the Strand or
            perhaps heard of the case of the Demeter? Pour yourself a stiff
            drink and I shall regale you with a tale which is as cold and dark
            as the crypt of Lord Blackwood in Highgate Cemetery.&quot;
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.drivethrurpg.com/product/391174/StokerVerse-Roleplaying-Game-Quickstart?fbclid=IwAR2K9j1L6vupTl3gWHlCQ5MVb-mmxrc5grIq0imP7YzZV624SLsRNriDA88"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      customImageStyles: styles.cardImage,
      displayOrder: 90,
    },

    {
      key: "a-whisper-in-the-darkness-audio-card",
      image: LazyImage(
        "BBV - A Whisper in the Darkness - Audio",
        AWhisperInTheDarknessImage,
        styles.containLogoImage
      ),
      title: "BBV - A Whisper in the Darkness - Audio",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Bram Stoker comes face-to-face with his deadliest creation...
          </div>
          <div css={styles.cardText}>
            An atmospheric short audio performance written by award-winning
            horror writer Chris McAuley, and the great-grandnephew of Bram
            Stoker himself - Dacre Stoker. Experience the official expansion of
            the Dracula mythos... as fact meets fiction...in the ‘StokerVerse’.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://bbvproductions.co.uk/products/A-Whisper-in-the-Darkness-AUDIO-DOWNLOAD-p503026512"
            )}
          </div>
        </Fragment>
      ),
      imageTop: false,
      customItemStyles: [styles.card, styles.cardRight],
      customImageStyles: styles.cardImage,
      displayOrder: 100,
    },

    {
      key: "/dracula-the-return-comic-issue-1-card",
      image: LazyImage(
        "Dracula The Return - Cult of the White Worm - Issue #1",
        DraculaTheReturnIssue1Image,
        styles.containLogoImage
      ),
      title: "Dracula The Return - Cult of the White Worm - Issue #1",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            For the first time in literary history, a member of the Stoker
            family is writing a comic script that officially continues the story
            of Dracula from the classic novel.
          </div>
          <div css={styles.cardText}>
            Cult Of The White Worm is the first mini series in the official
            continuation of the classic novel DRACULA by Bram Stoker, brought to
            you authentically by co-writers Dacre Stoker and Chris McAuley.
          </div>
          <div css={styles.cardText}>
            Contained in a 32-page comic book the story kicks off right at the
            end of the novel, and utilising Bram Stoker’s own notes, the iconic
            count is brought back to life in an authentic and faithful, yet
            fresh and exciting new way.
          </div>
          <div css={styles.cardText}>
            The first 21-pages are a full colour chapter of Cult Of The White
            Worm with stunning art by Chris Geary and Matt Soffe. We also reveal
            the mysterious 4th Bride and delve into the backstory of Dracula’s
            man servant Renfield in two 5 page back up comic strips, one of
            which is co written by Buffy The Vampire Slayer writer Denise
            Ciencin with art by the Eagle award winning David Hitchcock.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Dracula-Return-Book-Cult-White-ebook/dp/B0BRVRV7LW/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1678188371&sr=1-2"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      customImageStyles: styles.cardImage,
      displayOrder: 110,
    },
  ];

  const pageContent1: IPageContentProp = {
    title: "StokerVerse",
    items: [
      {
        key: "/stokerverse-1-page-content-block",
        content:
          "The StokerVerse is the brainchild of Chris McAuley and Dacre Stoker and encompasses all things ‘Horror’. As the name implies the range was originally inspired by the works of Bram Stoker. With a series of novels, comics and games being published, the StokerVerse is becoming known as one of the leading brands in the genre.",
      },
      {
        key: "/stokerverse-2-page-content-block",
        content: QuoteContent(
          "Chris McAuley crafts tales which truly take the reader into the world of the story. Leading you into the dark and introducing you to gripping narratives and creatures. His work and genius scare the absolute hell into me.",
          "Joe Pasquale"
        ),
      },
      {
        key: "/stokerverse-3-page-content-block",
        content:
          "The StokerVerse has ranges featuring best selling horror authors Paul Kane, Dacre Stoker, Denise Ciecin, Joe Pasqualle and John Peel.",
      },
      {
        key: "/stokerverse-4-page-content-block",
        content:
          "Through the StokerVerse we take readers into the hidden world of Dracula and beyond.",
      },
    ],
    sideContent: Image("Dacre Stoker", DacreStokerHeadshotImage, styles.fill),
  };

  const pageContent2: IPageContentProp = {
    title: "",
    customStyles: styles.pageContent2Wrapper,
    items: [
      {
        key: "/stokerverse-2-1-page-content-block",
        title: "",
        content: "",
      },
      {
        key: "/stokerverse-2-2-page-content-block",
        title: "",
        content: "",
      },
      {
        key: "/stokerverse-2-3-page-content-block",
        title: "Our Products",
        content: (
          <CardDeck
            items={[...sortByProperty(cardDeckItems, "displayOrder", false)]}
            defaultPageSize={-0}
            customStyles={styles.cardDeck}
          />
        ),
      },
    ],
  };

  return (
    <Fragment>
      <Head
        title="StokerVerse"
        url="https://www.dark-universes.com/stokerverse"
        description="StokerVerse - the brainchild of Chris McAuley and Dacre Stoker, and inspired by the works of Bram Stoker, let us take you into the hidden world of Dracula and beyond."
      />
      <CarouselComponent items={carouselItems} autoplay />
      <PageContent {...pageContent1} />
      <PageContent {...pageContent2} />
      <SocialBar />
    </Fragment>
  );
};

export default StokerVerse;
