import React, { Fragment, ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
// import { useLocation, NavLink } from "react-router-dom";
// import { DashboardIcon } from "src/app/assets/icons";
import Image from "src/components/image";
import LazyImage from "src/components/lazy-image";
import CarouselComponent, {
  ICarouselProp,
  ICarouselItem,
} from "src/components/carousel";
import CardDeck, { ICardDeckItem } from "src/components/card-deck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCartArrowDown,
  faFileDownload,
  faExternalLinkSquareAlt,
} from "@fortawesome/free-solid-svg-icons";

import DarkUniversesBannerLandscapeImage from "src/app/assets/images/dark-universes-banner-landscape.jpg";
import DarkUniversesBannerPortraitImage from "src/app/assets/images/dark-universes-banner-portrait.jpg";
import SpiderBloodImage from "src/app/assets/images/spider-blood.jpg";
import CandleSkullImage from "src/app/assets/images/candle-skull.jpg";
import BubbleImage from "src/app/assets/images/bubble.jpg";
import EarthImage from "src/app/assets/images/earth.jpg";
import FutureCityImage from "src/app/assets/images/future-city.jpg";
import MarsCityImage from "src/app/assets/images/mars-city.jpg";
import NASAEarthImage from "src/app/assets/images/nasa-earth.jpg";
import TrainStationImage from "src/app/assets/images/train-station.jpg";
// import ScarComicsLogoImage from "src/app/assets/images/scar-comics-logo.jpg";
// import StokerVerseLogoImage from "src/app/assets/images/stokerverse-logo.jpg";

import HouseOfSticthedImage from "src/app/assets/images/house-of-stitched.jpg";
import BabyLong5QuizImage from "src/app/assets/images/babylon5-quiz.jpg";
import DraculasBedlamImage from "src/app/assets/images/draculas-bedlam.jpg";
import VirginsEmbraceImage from "src/app/assets/images/virgins-embrace.jpg";
import DrWhoAnnualImage from "src/app/assets/images/dr-who-annual.jpg";
import SchlockImage from "src/app/assets/images/schlock.jpg";
import VoicesOfDraculaImage from "src/app/assets/images/voices-of-dracula.jpg";
import JamesBondQuizImage from "src/app/assets/images/james-bond-quiz.jpg";

import StokerVerseRPGQS1Image from "src/app/assets/images/stokerverse-rpg-qs1.jpg";
import TerminatorRPGImage from "src/app/assets/images/terminator-rpg.jpg";
import ToiletZoneImage from "src/app/assets/images/toilet-zone.jpg";
import VaultOfGoreImage from "src/app/assets/images/vault-of-gore.jpg";
import ManOWarImage from "src/app/assets/images/man-o-war.jpg";
import SchlockFeb2022Image from "src/app/assets/images/schlock-feb-2022.jpg";
import CosmicMasqueImage from "src/app/assets/images/cosmic-masque.jpg";
import OldScratchImage from "src/app/assets/images/old-scratch.jpg";
import OpenYourMouthImage from "src/app/assets/images/open-your-mouth.jpg";
import TheMuskateersVsCthuluImage from "src/app/assets/images/the-muskateers-vs-cthulu.jpg";
import LoveCraftianaVol7Image from "src/app/assets/images/lovecraftiana-vol-7.jpg";
import RenfieldVisionsOfMadnessImage from "src/app/assets/images/renfield-visions-of-madness.jpg";
import HorrorZineSpring2022Image from "src/app/assets/images/horror-zine-spring-2022.jpg";

import TerrorVisionImage from "src/app/assets/images/terror-vision.jpg";
import DraculaTheReturnIssue1Image from "src/app/assets/images/dracula-the-return-issue-1.jpg";
import HellboundAnthologyOfScienceFictionVolume1Image from "src/app/assets/images/hellbound-anthology-of-science-fiction-volume-1.jpg";
import ThroughTheGatesOfYogSothothImage from "src/app/assets/images/through-the-gates-of-yog-sothoth.jpg";
import DraculaBeyondStokerIssue1Image from "src/app/assets/images/dracula-beyond-stoker-issue-1.jpg";
import TheToiletZoneTheRoyalFlushImage from "src/app/assets/images/the-toilet-zone-the-royal-flush.jpg";
import TheHorrorZineSpring2023Image from "src/app/assets/images/the-horror-zine-spring-2023.jpg";
import SchlockVol17Issue6Image from "src/app/assets/images/schlock-vol-17-issue-6.jpg";
import DraculasGuestsImage from "src/app/assets/images/draculas-guests.jpg";
import HotOffThePressImage from "src/app/assets/images/hot-off-the-press.jpg";
import CurseOfTheCyberonsImage from "src/app/assets/images/curse-of-the-cyberons.jpg";
// import CurseOfTheCyberonsShipImage from "src/app/assets/images/curse-of-the-cyberons-ship.jpg";
import TheForbiddenActImage from "src/app/assets/images/the-forbidden-act.jpg";
import AWhisperInTheDarknessImage from "src/app/assets/images/a-whisper-in-the-darkness.jpg";
import CosmicMasqueIssue17Image from "src/app/assets/images/cosmic-masque-issue-17.jpg";
// import TheWeaponAndTheWarriorShipImage from "src/app/assets/images/the-weapon-and-the-warrior-ship.jpg";
import TheWeaponAndTheWarriorFaceImage from "src/app/assets/images/the-weapon-and-the-warrior-face.jpg";
import DarkLegaciesIssue1Variant2Image from "src/app/assets/images/dark-legacies-issue-1-variant 2.webp";
import DraculaOriginsImage from "src/app/assets/images/dracula-origins.jpg";
import DarkLegaciesFirstContactImage from "src/app/assets/images/dark-legacies-first-contact.jpg";
import PeterDarvillEvansTardisImage from "src/app/assets/images/peter-darvill-evans-tardis.jpg";

import HeartOfSteelSchlockThumbnailImage from "src/app/assets/images/heart-of-steel-schlock-mp4-thumbnail.jpg";
import HeartOfSteelSchlockVideo from "src/app/assets/videos/heart-of-steel-schlock.mp4";

import { sortByProperty } from "src/utils/sort";
import Head from "src/components/head";
// import VideoPlayer from "src/components/video";
import LazyVideoPlayer from "src/components/lazy-video";
import styles from "./styles";

const faIcon = (prop: IconProp) => (
  <FontAwesomeIcon css={styles.cardDeckFAIcon} icon={prop} fixedWidth />
);

const buyNowIcon = (url: string) => (
  <a css={styles.cardDeckNavLink} href={url} target="_blank" rel="noreferrer">
    {faIcon(faCartArrowDown)}
    Buy Now
  </a>
);

const downloadIcon = (url: string) => (
  <a
    css={[styles.cardDeckNavLink, styles.cardDeckLinkButtonDownload]}
    href={url}
    target="_blank"
    rel="noreferrer"
  >
    {faIcon(faFileDownload)}
    Download
  </a>
);

const externalLinkIcon = (url: string, title?: string) => (
  <a
    css={[styles.cardDeckNavLink, styles.cardDeckLinkButtonExternalWebsite]}
    href={url}
    target="_blank"
    rel="noreferrer"
  >
    {faIcon(faExternalLinkSquareAlt)}
    {title ?? "Visit Site"}
  </a>
);

const Home = (): ReactElement => {
  const carouselItems: Array<ICarouselItem> = [
    {
      key: "/dark-universes-banner-landscape-carousel",
      content: Image("Dark Universes", DarkUniversesBannerLandscapeImage, [
        styles.cover,
        styles.darkUniversesBannerLandscape,
      ]),
    },
    {
      key: "/nasa-earth-carousel",
      content: Image("Dark Universes", NASAEarthImage, styles.fill),
    },
    {
      key: "/spider-blood-carousel",
      content: Image("Dark Universes", SpiderBloodImage, [
        styles.cover,
        styles.spiderBlood,
      ]),
    },
    {
      key: "/candle-skull-carousel",
      content: Image("Dark Universes", CandleSkullImage, [
        styles.cover,
        styles.candleSkull,
      ]),
    },
    {
      key: "/future-city-carousel",
      content: Image("Dark Universes", FutureCityImage, styles.fill),
    },
    {
      key: "/mars-city-carousel",
      content: Image("Dark Universes", MarsCityImage, styles.fill),
    },
    {
      key: "/dark-universes-banner-portrait-carousel",
      content: Image("Dark Universes", DarkUniversesBannerPortraitImage, [
        styles.cover,
        styles.darkUniversesBannerPortrait,
      ]),
    },
    {
      key: "/earth-carousel",
      content: Image("Dark Universes", EarthImage, styles.fill),
    },
    {
      key: "/train-station-carousel",
      content: Image("Dark Universes", TrainStationImage, styles.cover),
    },
    {
      key: "/bubble-carousel",
      content: Image("Dark Universes", BubbleImage, styles.cover),
    },
  ];

  const cardDeckItems: Array<ICardDeckItem> = [
    {
      key: "/james-bond-the-ultimate-quiz-book-card",
      image: LazyImage(
        "James Bond - The Ultimate Quiz Book",
        JamesBondQuizImage,
        styles.containLogoImage
      ),
      title: "James Bond - The Ultimate Quiz Book",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Are you a big James Bond fan? Do you know your Nick Nack from your
            Oddjob, your Goldfinger from your Goldeneye and your Christmas from
            your May Day? If so, then this is the perfect book for you!
          </div>
          <div css={styles.cardText}>
            Written by Chris McAuley and Jack Goldstein, with five hundred
            questions (and answers) covering everything from Dr. No all the way
            through to No Time to Die, this Ultimate Quiz is ideal for testing
            your own knowledge or for playing along with friends.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.co.uk/James-Bond-Ultimate-Questions-Answers/dp/1789825024/ref=sr_1_3?crid=3NOLXELMUIL1G&keywords=james+bond+-+the+ultimate+quiz+book&qid=1651534494&sprefix=%2Caps%2C55&sr=8-3"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 10,
    },
    {
      key: "/virgins-embrace-a-stokerVerse-graphic-novel-card",
      image: LazyImage(
        "Virgin's Embrace",
        VirginsEmbraceImage,
        styles.containLogoImage
      ),
      title: "Virgin's Embrace - A StokerVerse Graphic Novel",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            The Virgin’s Embrace – the first StokerVerse graphic novel released
            by AUK is receiving rave reviews.
          </div>
          <div css={styles.cardText}>
            &quot;A look at the original work against our contemporary views -
            as well the inspiration and happenings that brought it about. The
            retelling and graphic elements expand the story in a more
            appropriate modern form but still set within the period intended.
            Nice read and a good visual impact to it&quot; - William
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.co.uk/Virgins-Embrace-adaptation-originally-Stokerverse/dp/1789825504/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1651534353&sr=8-1"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 20,
    },
    {
      key: "/babylon-5-the-ultimate-quiz-book-card",
      image: LazyImage(
        "Babylon 5 - The Ultimate Quiz Book",
        BabyLong5QuizImage,
        styles.containLogoImage
      ),
      title: "Babylon 5 - The Ultimate Quiz Book",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Written by Chris McAuley and Ian Elliott, with a forward by Claudia
            Christian. The Ultimate Babylon 5 quiz book is now released. Test
            your knowledge with 400 questions covering the entire universe.
          </div>
          <div css={styles.cardText}>
            Are you a huge fan of Babylon 5? Do you know your Anla’Shok from
            your Entil’Zha, your Tenth Fane of Elleya from your Third Fane of
            Chudomo and your sleepers from your dust? If so, this is the perfect
            quiz book for you! With four hundred questions (and answers)
            covering the entire Babylon 5 universe, this ultimate quiz is ideal
            for testing your own knowledge or for playing along with friends.
            Topics covered include the various races, planets and systems, the
            characters and their relationships, individual plot points and fun
            sections such as anagrams and guess the season. So put on your best
            ceremonial dress, strap yourself into your Starfury and get ready
            for some awesome Babylon 5 quizzing fun!
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.co.uk/Babylon-5-Ultimate-Quiz-Book/dp/1789826128/ref=sr_1_3?crid=1JB2PY280KUQI&keywords=babylon+5+quiz+book&qid=1651534135&sprefix=babylon+5+quiz+book%2Caps%2C68&sr=8-3"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 30,
    },
    {
      key: "/voices-of-dracula-stokerverse-audio-tales-card",
      image: LazyImage(
        "Voices of Dracula",
        VoicesOfDraculaImage,
        styles.containLogoImage
      ),
      title: "Voices of Dracula - StokerVerse Audio Tales",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Voices of Dracula - a 10-part series of short stories written by
            Chris McAuley and released by AUK Studios is now available on
            Audible.
          </div>
          <div css={styles.cardText}>
            120-years after the original release of Dracula, author Chris
            McAuley has teamed up with Dacre Stoker, great-grandnephew of Bram
            Stoker, to create a series of short story audio chapters set within
            the StokerVerse. Each story is narrated from the point of view of
            the characters we all know – or think we know – so well.
          </div>
          <div css={styles.cardText}>
            Narrated by some of the leading voice actors of today, these short
            stories are dark, twisted and of course hugely entertaining. Simon
            Templeman (Legacy of Kain, Dragon Age, Dead Space 3, Charmed and so
            much more) plays Dracula, with all the expertise and finesse one
            would expect from such a lauded performer, whilst Claudia Christian
            (Babylon 5, 9-1-1, Disney’s Atlantis: The Lost Empire) is the
            mysterious Thief. TV’s sixth Doctor, Colin Baker (Doctor Who,
            Kingdom, Hustle), masterfully narrates vampire hunter Van Helsing’s
            stories, whilst Terry Molloy - most famous for playing the Doctor’s
            on-screen nemesis Davros - takes the part of Renfield. Jessica
            Martin (Doctor Who, The Sandman) completes the line-up as Mina
            Harker.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.co.uk/Voices-Dracula-Short-StokerVerse-Stories/dp/B0992M27HB/ref=sr_1_1?crid=263C8U17FGR6&keywords=voices+of+dracula&qid=1651533987&sprefix=voices+of+dracula%2Caps%2C68&sr=8-1"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 40,
    },
    {
      key: "/draculas-bedlam-a-stokerverse-novel-card",
      image: LazyImage(
        "Dracula’s Bedlam",
        DraculasBedlamImage,
        styles.containLogoImage
      ),
      title: "Dracula’s Bedlam - A StokerVerse Novel",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Dracula’s Bedlam – the second Stokerverse novel released by AUK is
            now available.
          </div>
          <div css={styles.cardText}>
            Is there a place more enthralling than that of the Asylum? The
            insane lurk in the shadows with gibbering mouths and twisted minds…
            Dr. Seward’s asylum is particularly interesting; a serial killer has
            his mind peeled back, a mysterious nurse walks the halls with a
            sweet smile and devious mind… and, of course, there’s the enigmatic
            Mr. Renfield...
          </div>
          <div css={styles.cardText}>
            Set between the cracks of the original Dracula novel, the
            StokerVerse series hopes to shed a little light into the dark areas
            which were not wholly explored by author Bram Stoker. Familiar
            figures from Dracula and original characters created specifically
            for the StokerVerse appear in these dark and twisted tales.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.co.uk/Draculas-Bedlam-2-Dacre-Stoker/dp/1789828538/ref=sr_1_1?crid=1YNIKSPEHN4ZC&keywords=dracula%27s+bedlam&qid=1651534603&sprefix=dracula+bedlam%2Caps%2C85&sr=8-1"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 50,
    },
    {
      key: "/house-of-stitched-winter-edition-card",
      image: LazyImage(
        "House of Stitched",
        HouseOfSticthedImage,
        styles.containLogoImage
      ),
      title: "House of Stitched - Winter Edition",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            The Winter edition of House of Stitched magazine features two
            exclusive tales influenced by the Hellraiser universe created by
            Clive Barker. These stories, written by Chris McAuley, feature a
            forward by famed Horror writer and StokerVerse co-writer Paul Kane.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/House-Stitched-Winter-Lisa-Vasquez-ebook/dp/B09NZ795G9"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 60,
    },
    {
      key: "/heart-of-steel-schlock-magazine-card",
      image: LazyImage(
        "Schlock Magazine",
        SchlockImage,
        styles.containLogoImage
      ),
      imageTop: true,
      title: "Heart of Steel - Schlock Magazine",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Heart of Steel – A Dark Legacies story for Schlock Magazine will be
            released on the 3rd January from Schlock magazine. Crafted by Chris
            McAuley and Claudia Christian, this story focuses on Mech combat on
            Mars.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Schlock-Webzine-Vol-16-Issue-ebook/dp/B09P8WRXZF/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1651533066&sr=8-4"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 70,
    },
    {
      key: "/dr-who-meets-dark-universes-card",
      image: LazyImage(
        "Doctor Who Annual",
        DrWhoAnnualImage,
        styles.containLogoImage
      ),
      title: "Dr Who meets Dark Universes",
      content:
        "The Doctor Who Annual which celebrates Colin Baker’s Doctor is to be released in March 2022. It features several stories by Chris McAuley. The cover image shows one of Chris’ stories with the Cyberons, he has also created a story featuring the Master and Dracula.",
      customItemStyles: styles.card,
      displayOrder: 80,
    },
    {
      key: "/the-terminator-rpg-card",
      image: LazyImage(
        "The Terminator RPG",
        TerminatorRPGImage,
        styles.containLogoImage
      ),
      title: "The Terminator RPG",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Coming from Nightfall Games - The official Terminator RPG features
            franchise canon stories from Chris McAuley.
          </div>
          <div css={styles.cardText}>
            Inside he has written a story which shows the origin of the
            Terminators and gives insight into the mind of Skynet.
          </div>
          <div css={styles.cardText}>
            In ‘The Terminator RPG’, you play resistance fighters struggling
            against the machine onslaught of Skynet in an alternative and
            post-apocalyptic version of today - the once ‘far future’ of the
            2020s.
          </div>
          <div css={styles.cardText}>
            You can take the role of rebel time travellers sent back to various
            points in time to stop Skynet from altering history. You can even
            play natives of any historical time period, targeted by Terminators
            and trying to stay alive as a future hell is unleashed around you.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.kickstarter.com/projects/nightfall/the-terminator-rpg/posts/3493952?fbclid=IwAR2nGpQgFuEHo0pbc1zn15evyicwJKhYgP_mDJF3Z8zLTeUYO7JnCbQSAKM"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 90,
    },
    {
      key: "/the-toilet-zone-card",
      image: LazyImage(
        "The Toilet Zone",
        ToiletZoneImage,
        styles.containLogoImage
      ),
      title: "The Toilet Zone",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            From critically acclaimed Horror anthology editor Gerri Gray comes
            evil which lurks near the toilet bowl. This is a place where demons
            dwell and nightmares tend to be more than just bad dreams. Chris
            McAuley has written a piece which explores the underground crypts of
            the Victorian Empire. Inside is a hideous monster which has lurked
            there since the Roman Empire first set foot on the island. This
            story was praised for the number of horrific shocks which reportedly
            made one critic reluctant to sleep that night.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.ca/dp/B09VG2Y4LV?ref_=cm_sw_r_kb_dp_DV5Y9R27801FVSNQ8FB5&tag=hellboundbook-20&linkCode=kpe"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 100,
    },
    {
      key: "/the-horror-zine-spring-2022-card",
      image: LazyImage(
        "The Horror Zine - Spring 2022",
        HorrorZineSpring2022Image,
        styles.containLogoImage
      ),
      title: "The Horror Zine - Spring 2022",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            The Horror Zine is one of the most prestigious magazines available
            for the genre. Chris McAuley has stretched his poetry muscles and
            delivered several acclaimed pieces which tug at the dark heart
            strings. Also featuring other established talents, this is a
            selection of only the most inventive and captivating tales in
            Horror.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Horror-Zine-Magazine-Spring-2022-ebook/dp/B09RHPDTYV"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 110,
    },
    {
      key: "/madame-grays-vault-of-gore-card",
      image: LazyImage(
        "Madame Gray’s Vault of Gore",
        VaultOfGoreImage,
        styles.containLogoImage
      ),
      title: "Madame Gray’s Vault of Gore",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Critically acclaimed anthology editor Madame Gray brings some of the
            best horror writers together to write stories of gut-wrenching body
            horror. In this anthology Chris McAuley has crafted a tale of murder
            in Victorian London. A painter emerges from the foggy streets, his
            body dripping with blood. This red liquid is not his however. This
            is Jack the Ripper’s origin story, powerful and shocking. Pick it up
            today, just not after lunch!
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Madame-Grays-Vault-HellBound-Publishing-ebook/dp/B09LRHJR2V/ref=cm_cr_arp_d_product_top?ie=UTF8"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 120,
    },
    {
      key: "/william-shatners-man-o-war-card",
      image: LazyImage(
        "William Shatner’s Man O’War",
        ManOWarImage,
        styles.containLogoImage
      ),
      title: "William Shatner’s Man O’War",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Working with legendary Captain Kirk himself, Chris McAuley helps
            craft a story from the near future. Friction mounts between the
            Earth and its Martian colony. Only seasoned diplomat Benton Hawkes
            stands a chance to bring the warring factions together. This is
            Shatner at his best!
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.ca/Man-War-1-CJ-Henderson-ebook/dp/B0865TQS4D/ref=sr_1_21?qid=1647696323&refinements=p_27%3ACJ%20Henderson&s=digital-text&sr=1-21&text=CJ%20Henderson&fbclid=IwAR2Swp56CrvLUmkctAIZH4KR1CVzEikyL63H7KRb5qRQw-oiRoyn0hWgzvM"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 130,
    },
    {
      key: "/schlock-magazine-heart-of-steel-card",
      image: LazyImage(
        "Schlock Magazine: Heart of Steel",
        SchlockFeb2022Image,
        styles.containLogoImage
      ),
      title: "Schlock Magazine: Heart of Steel",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            This is the fourth instalment of ‘Heart of Steel’ a prequel series
            to the main Dark Universes science fiction series of products.
            Written by Chris McAuley and Claudia Christian, this focuses on Mech
            combat during a violent uprising on Mars. Already critically
            acclaimed this is one series of stories not to miss out on!
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/gp/product/B09R93XXQX/ref=dbs_a_def_rwt_hsch_vapi_tkin_p1_i4"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 140,
    },
    {
      key: "/doctor-who-cosmic-masque-xv-card",
      image: LazyImage(
        "Doctor Who: Cosmic Masque - Issue XV",
        CosmicMasqueImage,
        styles.containLogoImage
      ),
      title: "Doctor Who: Cosmic Masque - Issue XV",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Read this exclusive prequel to the 6th Doctor story ‘Revelation of
            the Daleks’. From the minds of Chris McAuley and celebrated Doctor
            Who novelist John Peel. Discover a heart-rending story of Dalek
            invasion and how the Doctor was surprised by one Dalek’s humanity.
            Praised for its chilling subject matter, this story was said to have
            been one that Dalek creator Terry Nation would have approved of.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {downloadIcon("http://www.dwasonline.uk/CM15_spreads_format.pdf")}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 150,
    },
    {
      key: "/old-scratch-anthology-card",
      image: LazyImage(
        "Old Scratch Anthology",
        OldScratchImage,
        styles.containLogoImage
      ),
      title: "Old Scratch Anthology",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            In this anthology which celebrates The Father of Lies, Beelzebub and
            the ‘Old One’, Chris McAuley has crafted a powerful Dracula origin
            story. Within these pages discover how Vlad was sent to the real
            school of sorcery known as the Scholomance. It was rumored
            throughout history that the prince who would one day be known as the
            ‘Son of the Dragon’ was given to the devil at this place. Discover
            the real origins of Dracula in this anthology today!
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/dp/B09YBZ3QHN/ref=cm_sw_r_apan_2QFH6QYFVRQKJW5KPNE1?fbclid=IwAR1e-SuzTkmxX8R8IkRO_YogY2zT7VuTJnu1gwTtMPvHNEWRC5TFYLCrPjM"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 160,
    },
    {
      key: "/open-your-mouth-a-charity-anthology-card",
      image: LazyImage(
        "Open your Mouth: A Charity Anthology",
        OpenYourMouthImage,
        styles.containLogoImage
      ),
      title: "Open your Mouth: A Feminist Charity Anthology",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            An anthology which assists the Woman’s Law society. Chris McAuley
            has joined a host of horror favourites to pen a unique tale. Set
            thirty years after the conclusion of Dracula, Mina Harker has lost
            everything. Her husband Jonathan has committed suicide and she is
            now outcast from society. Mina now investigates the occult and hopes
            to find the origins of the hellrifts which are opening across
            Europe. Read this tale which has been praised for the authenticity
            of its writing and character design now.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Open-Your-Mouth-Jessica-Raney-ebook/dp/B09WYPS94P/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1648667490&sr=8-3"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 170,
    },
    {
      key: "/the-muskateers-vs-cthulu-in-the-court-of-king-louis-card",
      image: LazyImage(
        "The Muskateers vs. Cthulu in the Court of King Louis",
        TheMuskateersVsCthuluImage,
        styles.containLogoImage
      ),
      title: "The Muskateers vs. Cthulu in the Court of King Louis",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Claudia Christian and Chris McAuley craft a story which sees a
            satanic ritual conjuring Cthulhu-like beasts in France. Only the
            Musketeers can avert this threat! This anthology also features tales
            from award winning Lovecraftian writers. Claudia and Chris’ story
            was so well received that a comic book sequel is in the works!
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.ca/dp/B09YQKQQB7?geniuslink=true&fbclid=IwAR0awZUDi_72cGF2yDTGbHmBiraOm8o4XBE-4NLZN0Ko5ANmdWF_Mk26nKQ"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 180,
    },
    {
      key: "/lovecraftiana-vol-7-walpurgisnacht-2022-card",
      image: LazyImage(
        "Lovecraftiana Vol 7, Walpurgisnacht 2022",
        LoveCraftianaVol7Image,
        styles.containLogoImage
      ),
      title: "Lovecraftiana Vol 7, Walpurgisnacht 2022",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Featuring the first part of a Fantasy story crafted by Claudia
            Christian and Chris McAuley, the prestigious Lovecraftiana is a must
            for fans of weird fiction. This is the initial installment in the
            Dark Universes ‘Dark Legends’ franchise. Demonic creatures permeate
            through the void to do battle with sword drawn heroes of renown and
            sorcerous mages. These are tales told in the style of Conan with
            axes and swords dripping copious amounts of blood.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Lovecraftiana-Vol-Issue-Walpurgisnacht-2022-ebook/dp/B09YVDBV2V/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1651534736&sr=8-1"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 190,
    },
    {
      key: "/renfield-visions-of-madness-comic-book-card",
      image: LazyImage(
        "Renfield: Visions of Madness",
        RenfieldVisionsOfMadnessImage,
        styles.containLogoImage
      ),
      title: "Renfield: Visions of Madness Comic Book",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Available from acclaimed indie comic book company Legacy Comix, this
            explores a prequel story involving Renfield. Written by Chris
            McAuley and illustrated by Steve Cange this is a terrifying and
            brutal narrative illustrated with an ‘art haus’ style.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.legacycomix.com/product/renfield-visions-of-madness-0-digital"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 200,
    },
    {
      key: "/stokerverse-rpg-card",
      image: LazyImage(
        "StokerVerse RPG",
        StokerVerseRPGQS1Image,
        styles.containLogoImage
      ),
      title: "StokerVerse RPG",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Now available from Nightfall games. The 90-page quickstart RPG is a
            ‘pay what you like’ introduction to the first official role-playing
            sequel to the original Dracula novel. There are eight scenes to play
            through from the minds of Dacre Stoker, Chris McAuley and game
            writer Andrew Brown. Featuring stunning dark gothic artwork from
            legendary 2000AD artist Clint Langely. This is one adventure not to
            be missed.
          </div>
          <div css={styles.cardText}>
            &quot;Dracula is dead, of that we should be certain. The brave
            Texan, Quincey Morris, pierced his chest with a bowie knife. Morris
            paid with his life when he severed the great vampire’s ties to the
            mortal world. I can see by the puzzled look on your face that you
            may not be familiar with the full facts of this story. You may have
            read of a mysterious Romanian Count’s disappearance in the Strand or
            perhaps heard of the case of the Demeter? Pour yourself a stiff
            drink and I shall regale you with a tale which is as cold and dark
            as the crypt of Lord Blackwood in Highgate Cemetery.&quot;
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.drivethrurpg.com/product/391174/StokerVerse-Roleplaying-Game-Quickstart?fbclid=IwAR2K9j1L6vupTl3gWHlCQ5MVb-mmxrc5grIq0imP7YzZV624SLsRNriDA88"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 210,
    },
    {
      key: "hellBounds-anthology-of-science-fiction-card",
      image: LazyImage(
        "HellBound’s Anthology of Science Fiction",
        HellboundAnthologyOfScienceFictionVolume1Image,
        styles.containLogoImage
      ),
      title: "HellBound Books’ Anthology of Science Fiction: Volume One",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Within these pages, curated by the inimitable Dr. Chris McAuley
            (co-founder of the StokerVerse, character creator for DC and Marvel,
            novelist, and Dr. Who author) is a baker’s dozen heart-thumping,
            mind-bending tales of science gone horribly awry, other, mysterious
            worlds, and the unspeakable horrors they spawn.
          </div>
          <div css={styles.cardText}>
            Featuring sci-fi tales from the likes of: Mike Adamson, Han Adcock,
            Richard Beauchamp, Simon Bleaken, Matt Brandenburg, Benjamin DeHaan,
            Sam Fletcher, Carlton Herzog, Steph Minns, Rob D. Smith, Joseph
            Hirsch, Dr. Chris McAuley, and the incomparable James H Longmore.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/HellBound-Books-Anthology-Science-Fiction-ebook/dp/B0BTJV9LLR/ref=sr_1_3?qid=1678188371&refinements=p_27%3AChris+McAuley&s=digital-text&sr=1-3"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 220,
    },
    {
      key: "the-horror-zine-spring-2023-card",
      image: LazyImage(
        "The Horror Zine Spring 2023",
        TheHorrorZineSpring2023Image,
        styles.containLogoImage
      ),
      title: "The Horror Zine Spring 2023",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Since 2009, The Horror Zine has published exceptional horror fiction
            by emerging talent and today’s leading authors. Editor Jeani Rector
            selects only the most inventive and captivating tales for each
            issue. In addition to short fiction, The Horror Zine features
            amazing poetry and artwork. Now The Horror Zine brings the dark
            delights from the ezine into a print magazine. Several of Chris’
            Dark poems are published here and have been acclaimed for their
            world building and imagination.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Horror-Zine-Magazine-Spring-2023-ebook/dp/B0BRHMG5RX/ref=sr_1_13?qid=1678188371&refinements=p_27%3AChris+McAuley&s=digital-text&sr=1-13"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 230,
    },
    {
      key: "dracula-beyond-stoker-issue-1-card",
      image: LazyImage(
        "Dracula Beyond Stoker - Issue #1",
        DraculaBeyondStokerIssue1Image,
        styles.containLogoImage
      ),
      title: "Dracula Beyond Stoker - Issue #1",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            A fiction journal dedicated to celebrating and continuing the legacy
            of Bram Stoker’s Dracula. This debut issue features nine all new
            fantastic stories and one classic reprint spotlighting the Count in
            a variety of styles, settings, and formats. Plus an essay examining
            the legacy of Blacula, and a brand new poem from Jesica Lévai.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Dracula-Beyond-Stoker-Issue-1-ebook/dp/B0BM52X3GG/ref=sr_1_8?qid=1678188371&refinements=p_27%3AChris+McAuley&s=digital-text&sr=1-8"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 240,
    },
    {
      key: "the-toilet-zone-the-royal-flush-card",
      image: LazyImage(
        "The Toilet Zone: The Royal Flush",
        TheToiletZoneTheRoyalFlushImage,
        styles.containLogoImage
      ),
      title: "The Toilet Zone: The Royal Flush",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            There exists a mysterious dimension outside the three known to man.
            It is a dark realm of unspeakable evils that extends from beyond the
            stars to the infernal regions lying deep within the human, or more
            fittingly, inhuman heart.
          </div>
          <div css={styles.cardText}>
            A place where demons dwell, where nightmares tend to be more than
            just bad dreams, and where anomalous terrors can rise up at any
            moment from the still, watery depths beneath you.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Toilet-Zone-Royal-Flush-ebook/dp/B09VG2Y4LV/ref=sr_1_12?qid=1678188371&refinements=p_27%3AChris+McAuley&s=digital-text&sr=1-12"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 250,
    },
    {
      key: "schlock-vol-17-issue-6-card",
      image: LazyImage(
        "Schlock Vol 17 Issue 6",
        SchlockVol17Issue6Image,
        styles.containLogoImage
      ),
      title: "Schlock Vol 17 Issue 6",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            This issue features a tale from Claudia Christian’s Dark Legacies.
            Notable for Chris McAuley and Peter Jurasik (Londo from Babylon 5)
            co-writing it! Follow an epic tale of a Hitman seeking revenge in
            New Hong Kong and discover more dark legacies!
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Schlock-Webzine-17-Issue-6-ebook/dp/B0BR5NRXB3/ref=sr_1_15?qid=1678188371&refinements=p_27%3AChris+McAuley&s=digital-text&sr=1-15"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 260,
    },
    {
      key: "draculas-guests-anthology-card",
      image: LazyImage(
        "Dracula’s Guests Anthology",
        DraculasGuestsImage,
        styles.containLogoImage
      ),
      title: "Dracula’s Guests Anthology",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            A century and a quarter ago, Bram Stoker unleashed the unbridled
            horror of Count Dracula upon the world. We can only wonder if he
            ever imagined the impact his creation would have on not only the
            horror world, but across the globe.
          </div>
          <div css={styles.cardText}>
            Vampires have become a foremost staple of horror stories and have
            been represented in both print and film countless times over the
            decades - and their mythos has grown unchecked, evolved, and taken
            root in the collective consciousness of old and young, horror fans
            and non-horror fans alike.
          </div>
          <div css={styles.cardText}>
            There’s just something about the immortal bloodsucking monsters that
            appeals to the ghoulish and the romantic individuals, and who has
            not mused upon the vampire’s lot in (after) life? To be free of the
            shackles of death, to fly unseen in the darkest of night, to seek
            sustenance from the blood of the living…
          </div>
          <div css={styles.cardText}>
            We have, within the pages of this superlative anthology, the most
            fitting homage to the vampire legend - a selection of tales by some
            of the very best independent horror authors writing in the genre
            today, each one with a unique spin on vampires and their deadly ilk.
          </div>
          <div css={styles.cardText}>Featuring:</div>
          <div css={styles.cardText}>
            Michael Zimecki, Trev Hill, Albert N. Katz, Paul Wilson, Ken
            Goldman, Eamonn Murphy, Judith Newlin, Jack Nash, Jerry Purdon,
            Terry Stock, Sam Fletcher, Anthony Regolino, Rose Strickman, Elaine
            Pascale, Holly Saiki, Gregory Von Dare, Helen Mihajlovic, Kay
            Hanifen, Stephen Patrick, Stephen Loiaconi, Josh Darling, Ravenna
            Blazecroft, Loki DeWitt, and Denise Ciencin and Dr. Chris McAuley.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Draculas-Guests-HellBound-Books-Publishing-ebook/dp/B0BNNXBBHL/ref=sr_1_14?qid=1678188371&refinements=p_27%3AChris+McAuley&s=digital-text&sr=1-14"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 270,
    },
    {
      key: "through-the-gates-of-yog-soggoth-card",
      image: LazyImage(
        "Through the Gates of Yog Soggoth - Lovecraftian Horror",
        ThroughTheGatesOfYogSothothImage,
        styles.containLogoImage
      ),
      title: "Through the Gates of Yog Soggoth - Lovecraftian Horror",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Now in Eighth Tower’s newest anthology, we see ten before
            unpublished Yog-Sothoth tales written by some of modern Weird
            Fiction’s best writers. From a tale of a man in a midlife crisis, to
            a brilliantly clever piece on a French revolutionary, we are
            overcome by this new Lovecraftian darkness, and in that darkness, we
            are invited to go deeper into the void.
          </div>
          <div css={styles.cardText}>
            Come and see the collector of magical objects who finds something
            not expected and then meet the woman in mourning over the death of
            her twin who quickly discovers that her dead sister had some very
            occult hobbies. Visit two lovers in crisis haunted by the ghost of
            an ex-girlfriend or follow a team of paranormal researchers in 1970s
            Britain who get far, far more than they bargained for. Finally meet
            the other denizens of this Lovecraftian tome: a denizen of
            Massachusetts known by his moniker The Nameless One who is hellishly
            bent on bringing ultimate evil through to this reality. Then try the
            story of a rock band on the verge of something unexpected. Or if
            music with a Scottish sound is more your taste, read the tale of a
            bagpipe player who meets a beautiful creature from his dreams, only
            to found that dream is in fact a nightmare. And finally enjoy a
            retelling of the classic Sherlock Holmes tale where Count Dracula
            makes an appearance.
          </div>
          <div css={styles.cardText}>
            In all these tales, our protagonists are confronted with the
            unimaginable reality of Yog-Sothoth and are thus driven mad, or
            worse. Join us in this new collection if you dare where we explore
            the terrifying and nihilistic, the absurdly and horribly humorous,
            and where again and again, our protagonists find that death might
            not be the worst thing waiting for us all.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Through-Gates-Yog-Sothoth-Raffaele-Pezzella-ebook/dp/B0B4X4CTTR/ref=sr_1_4?qid=1678188371&refinements=p_27%3AChris+McAuley&s=digital-text&sr=1-4"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 280,
    },
    {
      key: "hot-off-the-press-card",
      image: LazyImage(
        "Hot off the Press",
        HotOffThePressImage,
        styles.containLogoImage
      ),
      title: "Hot off the Press",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Hot Off the Press is the latest charity anthology from Red Cape
            Publishing, compiled by Jay Michaels and Monster Smith in aid of
            FAST UK - the Foundation for Angelman Syndrome Therapeutics.
          </div>
          <div css={styles.cardText}>
            This anthology features over forty pieces of flash fiction from some
            of the biggest names in the indie horror community, including
            screenwriters, actors, directors, authors and producers.
          </div>
          <div css={styles.cardText}>
            So dim the lights, draw the curtains, and settle in for an onslaught
            of short bites of fiction.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Hot-Off-Press-Charity-Anthology-ebook/dp/B0BJ2RSMZD/ref=sr_1_20?qid=1678188973&refinements=p_27%3AChris+McAuley&s=digital-text&sr=1-20"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 290,
    },
    {
      key: "a-whisper-in-the-darkness-audio-card",
      image: LazyImage(
        "BBV - A Whisper in the Darkness - Audio",
        AWhisperInTheDarknessImage,
        styles.containLogoImage
      ),
      title: "BBV - A Whisper in the Darkness - Audio",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Bram Stoker comes face-to-face with his deadliest creation...
          </div>
          <div css={styles.cardText}>
            An atmospheric short audio performance written by award-winning
            horror writer Chris McAuley, and the great-grandnephew of Bram
            Stoker himself - Dacre Stoker. Experience the official expansion of
            the Dracula mythos... as fact meets fiction...in the ‘StokerVerse’.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://bbvproductions.co.uk/products/A-Whisper-in-the-Darkness-AUDIO-DOWNLOAD-p503026512"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 300,
    },
    {
      key: "cosmic-masque-issue-17-card",
      image: LazyImage(
        "Cosmic Masque - Issue XVII",
        CosmicMasqueIssue17Image,
        styles.containLogoImage
      ),
      title: "Cosmic Masque - Issue XVII",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Terry Molloy (Davros) co-writes with Chris McAuley to tell the
            Dalek’s origins story - straight from the creators mouth.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {downloadIcon("http://www.dwasonline.uk/CM17_STD_format.pdf")}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 310,
    },
    {
      key: "the-forbidden-act-audio-card",
      image: LazyImage(
        "BBV - The Forbidden Act - Audio",
        TheForbiddenActImage,
        styles.containLogoImage
      ),
      title: "BBV - The Forbidden Act - Audio",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Entranced by a mysterious vampiric female, a lover commits unholy
            acts in pure devotion.
          </div>
          <div css={styles.cardText}>
            The first tale in the ‘Classic Monsters’ range by Chris McAuley
            (co-creator of the StokerVerse / Dark Universes).
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://bbvproductions.co.uk/products/The-Forbidden-Act-AUDIO-DOWNLOAD-p520081678"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 320,
    },
    {
      key: "curse-of-the-cyberons-audio-card",
      image: LazyImage(
        "BBV - Curse of the Cyberons - Audio (Doctor Who Spin Off)",
        CurseOfTheCyberonsImage,
        styles.containLogoImage
      ),
      title: "BBV - Curse of the Cyberons - Audio (Doctor Who Spin Off)",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            In the depths of space, an ancient dark force of the past encounters
            evil forces of the future - the Cyberons!
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://bbvproductions.co.uk/products/Curse-of-the-Cyberons-AUDIO-DOWNLOAD-p488550738?mc_cid=73e8d08101&mc_eid=431b1a748f"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 330,
    },
    {
      key: "/heart-of-steel-schlock-video-card",
      image: LazyVideoPlayer({
        url: HeartOfSteelSchlockVideo,
        style: [styles.containLogoImage, styles.containVideoThumbnailImage],
        light: HeartOfSteelSchlockThumbnailImage,
      }),
      title: "Heart of Steel - Schlock",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Now available from Schlock, the first 6 stories in the acclaimed
            ‘Heart of Steel’ series, a part of the Dark Legacies universe. This
            is one adventure not to be missed!
          </div>
          <div css={[styles.cardText, styles.cardTextActionHeading]}>
            <div css={[styles.cardTextActionHeadingItem]}>Part 1</div>
            <div css={[styles.cardTextActionHeadingItem]}>Part 2</div>
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            <div css={[styles.cardTextActionItem]}>
              {buyNowIcon(
                "https://www.amazon.com/Schlock-Webzine-Vol-16-Issue-ebook/dp/B09P8WRXZF/ref=sr_1_1?crid=12YHNEJRF8FF9&keywords=schlock+vol+16+issue+24&qid=1679007660&sprefix=schlock+vol+16+issue+24%2Caps%2C157&sr=8-1"
              )}
            </div>
            <div css={[styles.cardTextActionItem]}>
              {buyNowIcon(
                "https://www.amazon.com/Schlock-Webzine-Vol-16-Issue-ebook/dp/B09R93XXQX/ref=sr_1_1?crid=LQM4L0RABBPO&keywords=schlock+vol+16+issue+25&qid=1679007676&sprefix=schlock+vol+16+issue+25%2Caps%2C188&sr=8-1"
              )}
            </div>
          </div>
          <div css={[styles.cardText, styles.cardTextActionHeading]}>
            <div css={[styles.cardTextActionHeadingItem]}>Part 3</div>
            <div css={[styles.cardTextActionHeadingItem]}>Part 4</div>
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            <div css={[styles.cardTextActionItem]}>
              {buyNowIcon(
                "https://www.amazon.com/Schlock-Webzine-Vol-16-Issue-ebook/dp/B09TFTLPJS/ref=sr_1_1?crid=WAD3BKCQVHKV&keywords=schlock+vol+16+issue+26&qid=1679007681&sprefix=schlock+vol+16+issue+26%2Caps%2C161&sr=8-1"
              )}
            </div>
            <div css={[styles.cardTextActionItem]}>
              {buyNowIcon(
                "https://www.amazon.com/Schlock-Webzine-Vol-16-Issue-ebook/dp/B09WN9DLYK/ref=sr_1_1?crid=1LY7EG1P907AQ&keywords=schlock+vol+16+issue+27&qid=1679007685&sprefix=schlock+vol+16+issue+27%2Caps%2C159&sr=8-1"
              )}
            </div>
          </div>
          <div css={[styles.cardText, styles.cardTextActionHeading]}>
            <div css={[styles.cardTextActionHeadingItem]}>Part 5</div>
            <div css={[styles.cardTextActionHeadingItem]}>Part 6</div>
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            <div css={[styles.cardTextActionItem]}>
              {buyNowIcon(
                "https://www.amazon.com/Schlock-Webzine-Vol-16-Issue-ebook/dp/B09YVLP7QK/ref=sr_1_1?crid=CMPR4N0IPWF0&keywords=schlock+vol+16+issue+28&qid=1679007694&sprefix=schlock+vol+16+issue+28%2Caps%2C218&sr=8-1"
              )}
            </div>
            <div css={[styles.cardTextActionItem]}>
              {buyNowIcon(
                "https://www.amazon.com/Schlock-Webzine-Vol-16-Issue-ebook/dp/B0B2K68JHL/ref=sr_1_1?crid=TZ7TJ44NW6TL&keywords=schlock+vol+16+issue+29&qid=1679007700&sprefix=schlock+vol+16+issue+29%2Caps%2C234&sr=8-1"
              )}
            </div>
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 340,
    },
    {
      key: "the-weapon-and-the-warrior-audio-card",
      image: LazyImage(
        "BBV - The Weapon and The Warrior - Audio",
        TheWeaponAndTheWarriorFaceImage,
        styles.containLogoImage
      ),
      title: "BBV - The Weapon and The Warrior - Audio",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            With a powerful new cyborg leader, the Cyberon conquest continues.
            Seemingly unstoppable, can one weapon save humanity...
          </div>
          <div css={styles.cardText}>
            Continuing the events of Curse of the Cyberons by Chris McAuley.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://bbvproductions.co.uk/products/The-Weapon-and-The-Warrior-AUDIO-DOWNLOAD-p489244506"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 350,
    },
    {
      key: "/terror-vision-card",
      image: LazyImage(
        "TerrorVision - Stories Inspired by Cult Horror and Sci-fi Films",
        TerrorVisionImage,
        styles.containLogoImage
      ),
      title: "TerrorVision - Stories Inspired by Cult Horror and Sci-fi Films",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            TerrorVision is a unique blend of horror and science fiction which
            pays homage to some of the best movies and franchises cinematic and
            televisual horror history. During the course of my writing career, I
            have been fortunate to have worked on franchises such as The
            Terminator, Doctor Who, Dracula, Hellraiser and my own creations.
            With this in mind I believe that I can say with some authority that
            each of the stories present in this anthology will take you on a
            wild ride.
          </div>
          <div css={styles.cardText}>
            The genesis of this book came from the current trend to write
            narratives inspired by cinema. Expanding upon the universes we only
            glimpse in the film’s runtime. Let’s face it, we all wanted to know
            more about planet LV-426 or how the machines became sentient in the
            Terminator universe. There are so many compelling stories to be told
            in these realms, far more that can be expressed in a runtime of 90
            minutes or even 120.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/TerrorVision-Stories-Inspired-Horror-Sci-fi-ebook/dp/B0BS2F6D1X/ref=sr_1_1?crid=3HE62MQPYQTO&keywords=TerrorVision.+Stories+Inspired+by+Cult+Horror+and+Sci-fi+Films&qid=1678988969&sprefix=terrorvision.+stories+inspired+by+cult+horror+and+sci-fi+films%2Caps%2C143&sr=8-1"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 360,
    },
    {
      key: "/dracula-the-return-comic-issue-1-card",
      image: LazyImage(
        "Dracula The Return - Cult of the White Worm - Issue #1",
        DraculaTheReturnIssue1Image,
        styles.containLogoImage
      ),
      title: "Dracula The Return - Cult of the White Worm - Issue #1",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            For the first time in literary history, a member of the Stoker
            family is writing a comic script that officially continues the story
            of Dracula from the classic novel.
          </div>
          <div css={styles.cardText}>
            Cult Of The White Worm is the first mini series in the official
            continuation of the classic novel DRACULA by Bram Stoker, brought to
            you authentically by co-writers Dacre Stoker and Chris McAuley.
          </div>
          <div css={styles.cardText}>
            Contained in a 32-page comic book the story kicks off right at the
            end of the novel, and utilising Bram Stoker’s own notes, the iconic
            count is brought back to life in an authentic and faithful, yet
            fresh and exciting new way.
          </div>
          <div css={styles.cardText}>
            The first 21-pages are a full colour chapter of Cult Of The White
            Worm with stunning art by Chris Geary and Matt Soffe. We also reveal
            the mysterious 4th Bride and delve into the backstory of Dracula’s
            man servant Renfield in two 5 page back up comic strips, one of
            which is co written by Buffy The Vampire Slayer writer Denise
            Ciencin with art by the Eagle award winning David Hitchcock.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://www.amazon.com/Dracula-Return-Book-Cult-White-ebook/dp/B0BRVRV7LW/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1678188371&sr=1-2"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 370,
    },
    {
      key: "dark-legacies-comic-issue-1-card",
      image: LazyImage(
        "Claudia Christian’s Dark Legacies - Issue #1",
        DarkLegaciesIssue1Variant2Image,
        styles.containLogoImage
      ),
      title: "Claudia Christian’s Dark Legacies - Issue #1",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Created by Claudia Christian and Chris McAuley comes a hard hitting
            sci-fi action series which has been described as ‘the expanse meets
            the terminator’.
          </div>
          <div css={styles.cardText}>
            The story entitled ‘Prey’ takes place within a pivotal point in the
            Dark Legacies timeline as Earth is emerging from a dystopian past,
            moving from a brutal governmental regime to democracy.
          </div>
          <div css={styles.cardText}>
            Our main protagonist, Major Jessica Steele, finds herself caught in
            a web of intrigue as the legacies of the totalitarian past surround
            her.
          </div>
          <div css={styles.cardText}>
            Dark secrets are uncovered and all hell is let loose in this all
            action blockbuster.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://theshift.store/products/claudia-christians-dark-legacies-1"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 380,
    },
    {
      key: "dracula-origins-audio-card",
      image: LazyImage(
        "BBV - Dracula: Origins - Audio",
        DraculaOriginsImage,
        styles.containLogoImage
      ),
      title: "BBV - Dracula: Origins - Audio",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Experience a macabre tale of darkness, exploring the universe of
            Bram Stoker’s gothic legend DRACULA.
          </div>
          <div css={styles.cardText}>
            A new world of gods & monsters narrated by Samantha Underhill, from
            the mind of StokerVerse and Dark Legacies creator Chris McAuley.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://bbvproductions.co.uk/products/Dracula-Origins-AUDIO-DOWNLOAD-p552593950"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 390,
    },
    {
      key: "peter-darvill-evans-card",
      image: LazyImage(
        "Peter Darvill-Evans",
        PeterDarvillEvansTardisImage,
        styles.containLogoImage
      ),
      title: "Peter Darvill-Evans geeks out on Dark Universes!",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            We’re absolutely thrilled to share that the legendary Peter
            Darvill-Evans, renowned author of iconic Fighting Fantasy gamebooks
            and publisher of many captivating Doctor Who books, amongst much
            else, is officially a fan of our Dark Universes and StokerVerse
            franchises!
          </div>
          <div css={styles.cardText}>
            It’s like having a cosmic endorsement!
          </div>
          <div css={styles.cardText}>
            You can explore Peter’s captivating universe by visiting his website
            below.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {externalLinkIcon("https://peterdarvillevans.com/")}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 400,
    },
    {
      key: "dark-legacies-first-contact-audio-card",
      image: LazyImage(
        "BBV - Claudia Christian’s Dark Legacies - First Contact - Audio",
        DarkLegaciesFirstContactImage,
        styles.containLogoImage
      ),
      title: "BBV - Claudia Christian’s Dark Legacies - First Contact - Audio",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            The debut Dark Legacies audio adventure, created by BABYLON 5 star
            Claudia Christian & StokerVerse founder Chris McAuley.
          </div>
          <div css={styles.cardText}>
            In a vast new universe, our future is overshadowed by powerful
            cybernetic entities.
          </div>
          <div css={[styles.cardText, styles.cardTextAction]}>
            {buyNowIcon(
              "https://bbvproductions.co.uk/products/Claudia-Christians-DARK-LEGACIES-01-First-Contact-AUDIO-DOWNLOAD-p567864339"
            )}
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 410,
    },
  ];

  /*
  const cardDeckItems2: Array<ICardDeckItem> = [
    {
      key: "/dark-legacies-card",
      image: Image(
        "Dark Legacies",
        ScarComicsLogoImage,
        styles.containLogoImage
      ),
      title: "SCAR announces series of Dark Legacies comics",
      content:
        "Indie comic book studio SCAR Comics have announced a deal for a minimum 4 issue range of comics in the Dark Legacies universe.",
      customStyles: styles.card,
    },
    {
      key: "/tarantino-card",
      title: "Tarantino loves the StokerVerse!",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            Acclaimed Hollywood director Quentin Tarantino has been in touch to
            give a his thoughts on Dr Chris&apos; writing and the tales of the
            StokerVerse.
          </div>
          <div css={styles.cardText}>
            &quot;As I listened, I felt myself encased in the environment of the
            story. He *Chris* has a natural voice for storytelling.&quot; -
            Tarantino
          </div>
        </Fragment>
      ),
      customStyles: styles.card,
    },
    {
      key: "/scar-stokerverse-card",
      image: Image("StokerVerse", StokerVerseLogoImage, [
        styles.containLogoImage,
        styles.stokerVerseLogoImage,
      ]),
      title: "SCAR Comics takes a bite out of the StokerVerse",
      content: (
        <div>
          SCAR Comics have signed a deal for their first StokerVerse comic. This
          will be the first of their planned range of comics set in the Dark
          Univserses.
        </div>
      ),
      customStyles: styles.card,
    },
  ];
  */

  return (
    <Fragment>
      <Head
        title="Dark Universes"
        url="https://www.dark-universes.com/"
        description="Home of the dark universes - Dark Realms, Dark Legacies, Dark Legends and the StokerVerse."
      />
      <CarouselComponent items={carouselItems} autoplay />
      <CardDeck
        items={[...sortByProperty(cardDeckItems, "displayOrder", false)]}
        defaultPageSize={12}
      />
    </Fragment>
  );
};

export default Home;
