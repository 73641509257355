import { css } from "@emotion/react";
import theme from "src/theme";
// import { queries } from "../../../utils/media-queries";

export default {
  socialBarWrapper: css`
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  `,
  socialBar: css`
    padding: 20px 0;
    width: 1280px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    background: ${theme.colors.white};
  `,
  socialBarFAIcon: css`
    margin: 0 10px 0 20px;
    font-size: 24px;
  `,
  navLink: css`
    text-decoration: none !important;
    display: flex;
    align-items: center;
    line-height: 40px;

    :hover {
      color: ${theme.colors.functional.red};
    }
  `,
};
