import React, { Fragment, ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import Image from "src/components/image";
import CarouselComponent, { ICarouselItem } from "src/components/carousel";
import PageContent, {
  IPageContentProp,
  QuoteContent,
} from "src/components/page-content";
import SocialBar from "src/components/social-bar";
import RaiderBattleImage from "src/app/assets/images/dark-legacies-raider-battle.png";
import EarthImage from "src/app/assets/images/earth.jpg";
import FutureCityImage from "src/app/assets/images/future-city.jpg";
import MarsCityImage from "src/app/assets/images/mars-city.jpg";
import NASAEarthImage from "src/app/assets/images/nasa-earth.jpg";
import TrainStationImage from "src/app/assets/images/train-station.jpg";
// import ClaudiaChristianHeadshotImage from "src/app/assets/images/claudia-christian-headshot-black-front.png";
import ClaudiaChristianHeadshotImage from "src/app/assets/images/claudia-christian-headshot-black-side.png";
import Head from "src/components/head";
import styles from "./styles";

const DarkLegacies = (): ReactElement => {
  const carouselItems: Array<ICarouselItem> = [
    {
      key: "/raider-carousel",
      content: Image("Dark Legacies", RaiderBattleImage, styles.fill),
    },

    {
      key: "/future-city-carousel",
      content: Image("Dark Legacies", FutureCityImage, styles.fill),
    },
    {
      key: "/earth-carousel",
      content: Image("Dark Legacies", EarthImage, styles.fill),
    },
    {
      key: "/mars-city-carousel",
      content: Image("Dark Legacies", MarsCityImage, styles.fill),
    },
    {
      key: "/train-station-carousel",
      content: Image("Dark Legacies", TrainStationImage, styles.cover),
    },
    {
      key: "/nasa-earth-carousel",
      content: Image("Dark Legacies", NASAEarthImage, styles.fill),
    },
  ];

  const pageContent: IPageContentProp = {
    title: "Dark Legacies",
    items: [
      {
        key: "/dark-legacies-1-page-content-block",
        content:
          "Dark Legacies is a Science Fiction universe created by Chris McAuley and Babylon 5 legend Claudia Christian. It is set after Earth emerges from a brutal totalitarian regime and looks towards the stars with a renewed sense of hope. However, the dark legacies of the past remain and threaten to drag humanity back to darker times.",
      },
      {
        key: "/dark-legacies-2-page-content-block",
        content:
          "Through a series of novel, comics, games and audio dramas we will explore the struggles of Major Jessica Steele. A special ops commander who has a tortured past. She must uncover the conspiracies which could destroy the heart of the new government as well as reconcile her own demons.",
      },
      {
        key: "/dark-legacies-3-page-content-block",
        content: QuoteContent(
          "Although we look towards the future with hope in our series, part of the dramatic tension comes from the legacies of the past – elements attempting to destabilize the newly elected democratic global government. We also explore the legacies which Jessica Steele (Claudia’s character) must contend with as she attempts to investigate the approaching danger. Jessica has a painful past but she doesn’t wallow in it or let melancholy overwhelm her. She dedicates herself to ensuring that humanity doesn’t step backwards into totalitarianism again.",
          "Claudia Christian"
        ),
      },
      {
        key: "/dark-legacies-4-page-content-block",
        content:
          "The franchise will also encompass stories which show the timeline of the political revolution between the dictatorial Con-Fed government and the democratic United Planetary Alliance.",
      },
      {
        key: "/dark-legacies-5-page-content-block",
        content: QuoteContent(
          "Battles in Mech suits, sieges of planetary colonies, betrayal, hope and epic space battles. This is a series which should resonate with fans of Babylon 5 and contemporary sci-fi such as The Expanse.",
          "Chris McAuley"
        ),
      },
    ],
    sideContent: Image(
      "Claudia Christian",
      ClaudiaChristianHeadshotImage,
      styles.fill
    ),
  };

  return (
    <Fragment>
      <Head
        title="Dark Legacies"
        url="https://www.dark-universes.com/dark-legacies"
        description="Dark Legacies - where the legacies of the past threaten to drag humanity back to darker times. Created by Chris McAuley and Babylon 5 legend Claudia Christian this Science Fiction universe is set after Earth emerges from a brutal totalitarian regime and looks towards the stars with a renewed sense of hope."
      />
      <CarouselComponent items={carouselItems} autoplay />
      <PageContent {...pageContent} />
      <SocialBar />
    </Fragment>
  );
};

export default DarkLegacies;
