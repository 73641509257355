import React, { Fragment, ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import Image from "src/components/image";
import CarouselComponent, { ICarouselItem } from "src/components/carousel";
import PageContent, {
  InlineEmphasisContent,
  IPageContentProp,
  QuoteContent,
} from "src/components/page-content";
import SocialBar from "src/components/social-bar";
import HoldingHandsImage from "src/app/assets/images/holding-hands.jpg";
import ManyHelpingHandsImage from "src/app/assets/images/many-helping-hands.jpg";
import SunsetHelpingHandImage from "src/app/assets/images/sunset-helping-hand.jpg";
import ManSuccessImage from "src/app/assets/images/man-success.jpg";
import TwoRoutesImage from "src/app/assets/images/two-routes.jpg";
import PeopleSuccessImage from "src/app/assets/images/people-success.jpg";
import C3SunriseImage from "src/app/assets/images/c3-sunrise.jpeg";
import C3BannerImage from "src/app/assets/images/c3-banner.jpg";
import ImageWithText from "src/components/image-with-text";
import { sortByProperty } from "src/utils/sort";
import CardDeck, { ICardDeckItem } from "src/components/card-deck";
import Head from "src/components/head";
import styles from "./styles";

const CThreeAbout = (): ReactElement => {
  const carouselItems: Array<ICarouselItem> = [
    {
      key: "/c3-sunrise-carousel",
      content: ImageWithText(
        "Dark Legends",
        C3SunriseImage,
        "The C Three Foundation is the world's only nonprofit organization dedicated to raising awareness of the Sinclair Method for treating and preventing alcohol use disorder.",
        styles.darkOverlay,
        [styles.cover, styles.darkOverlayImage]
      ),
    },
    {
      key: "/two-routes-carousel",
      content: Image("Dark Legends", TwoRoutesImage, [
        styles.cover,
        styles.centerTwoRoutes,
      ]),
    },
    {
      key: "/many-helping-hands-carousel",
      content: ImageWithText(
        "Dark Legends",
        ManyHelpingHandsImage,
        "Options Save Lives",
        styles.darkOverlay,
        [styles.cover, styles.darkOverlayImage],
        styles.overlayImageLargeText
      ),
    },
    {
      key: "/sunset-helping-hand-carousel",
      content: Image("Dark Legends", SunsetHelpingHandImage, styles.cover),
    },
    {
      key: "/man-success-carousel",
      content: Image("Dark Legends", ManSuccessImage, styles.cover),
    },
    {
      key: "/holding-hands-carousel",
      content: ImageWithText(
        "Dark Legends",
        HoldingHandsImage,
        "Ready to begin?",
        styles.darkOverlay,
        [styles.cover, styles.darkOverlayImage],
        styles.overlayImageLargeText
      ),
    },
    {
      key: "/people-success-carousel",
      content: Image("Dark Legends", PeopleSuccessImage, styles.cover),
    },
  ];

  const cardDeckItems: Array<ICardDeckItem> = [
    {
      key: "/awareness-card",
      title: "Awareness",
      content: (
        <Fragment>
          <div css={styles.cardText}>
            We seek to inform the public about responsible alcohol use and
            effective, lasting treatment for misuse and addiction. For nearly a
            century, alcohol treatment has been dominated by the belief that a
            complete and permanent state of sobriety is the only way to help an
            &apos;alcoholic.&apos; Even though science disproves this belief,
            many physicians and the general public are unaware of the
            advancements and breakthroughs in alcohol use disorder treatment.
          </div>
        </Fragment>
      ),
      customItemStyles: styles.card,
      displayOrder: 10,
    },
    {
      key: "/advocacy-card",
      title: "Advocacy",
      content:
        "We strive to identify, share, and advocate for policies, practices, laws, and social norms that support successful alcohol addiction recovery. The current medical, legal, and social constructs are rooted in traditions that are often counterproductive to effective treatment. We believe that it is important to understand the current political and social climate of both the alcohol and alcohol treatment industries in order to direct our efforts and affect the most change.",
      customItemStyles: styles.card,
      displayOrder: 20,
    },
    {
      key: "/education-card",
      title: "Education",
      content:
        "We diligently seek and share relevant information to better assist members of our global community in making informed, responsible decisions about their personal alcohol use and possible treatment options. We are committed to the promotion of alcohol addiction treatment options, such as the Sinclair Method (TSM), that are based in sound scientific research.",
      customItemStyles: styles.card,
      displayOrder: 30,
    },
  ];

  const c3Motto = InlineEmphasisContent("Options Save Lives");
  const c3Foundation = InlineEmphasisContent("C Three Foundation");

  const pageContent1: IPageContentProp = {
    title: "C Three Foundation",
    items: [
      {
        key: "/c3-1-1-page-content-block",
        content:
          "We at Dark Universes understand that there are many imperilments in life that can lead any of us to the truely dark places and we passionately believe that we all have a responsibility to do what we can to help others; to provide encouragement and support, to help people overcome their demons and regain their power and passion for life.",
      },
      {
        key: "/c3-1-2-page-content-block",
        content: (
          <Fragment>
            <div>
              This is the reason why we have selected the {c3Foundation} as the
              official Charity of the Dark Universes.
            </div>
          </Fragment>
        ),
      },
      {
        key: "/c3-1-3-page-content-block",
        content: (
          <Fragment>
            <div>
              The purpose of the C Three Foundation is to support individuals
              struggling with alcohol addiction through education and awareness.
              In accordance with their motto, {c3Motto}, they believe that
              successful recovery requires a multifaceted approach.
            </div>
          </Fragment>
        ),
      },
      {
        key: "/c3-1-4-page-content-block",
        title: "Mission",
        content:
          "The C Three Foundation was created to help save the lives of alcoholics worldwide. They aim to educate doctors, alcoholics, family and friends of alcohol-dependent people about the most successful long term treatment for alcoholism: the Sinclair Method.",
      },
      {
        key: "/c3-1-5-page-content-block",
        title: "Vision",
        content:
          "The C Three Foundation envisions a world where every person, regardless of ethnic, social, or economic background, is given equal access to affordable, scientifically tested treatment options for alcohol use disorder.",
      },
    ],
    sideContent: Image("C Three Foundation", C3BannerImage, styles.fill),
  };

  const pageContent2: IPageContentProp = {
    title: "",
    customStyles: styles.pageContent2Wrapper,
    items: [
      {
        key: "/c3-3-page-content-block",
        content: (
          <CardDeck
            items={[...sortByProperty(cardDeckItems, "displayOrder", false)]}
            defaultPageSize={-0.0}
          />
        ),
      },
      {
        key: "/c3-4-page-content-block",
        title: "The Sinclair Method",
        content:
          "The Sinclair Method (TSM) is a treatment for alcohol addiction that uses a technique called pharmacological extinction — the use of an opiate blocker to turn habit-forming behaviors into habit-erasing behaviors. The effect returns a person’s craving for alcohol to its pre-addiction state.",
      },
      {
        key: "/c3-5-page-content-block",
        content:
          "TSM consists of taking an opiate blocker one hour before your first drink of the day for the rest of your life as long as you continue to drink. The opiate blocker chemically disrupts the body’s behavior/reward cycle causing you to want to drink less instead of more.",
      },
      {
        key: "/c3-6-page-content-block",
        content: QuoteContent(
          "The Sinclair Method has a 78% long-term success rate."
        ),
      },
      {
        key: "/c3-7-page-content-block",
        content:
          "Studies have proven that TSM is equally effective with or without therapy, so patients can choose whether or not to combine TSM with therapy. The physical results will be the same. Extinction usually occurs within 3-4 months.",
      },
      {
        key: "/c3-8-page-content-block",
        content:
          "About one quarter of those on TSM become 100% abstinent. Those who continue to drink will have to take their medication prior to drinking for as long as they continue to drink.",
      },
      {
        key: "/c3-9-page-content-block",
        content: (
          <div>
            <div css={[styles.contentTitleHeading, styles.center]}>
              Make a Difference
            </div>
            <div>
              Worldwide, 3 million deaths every year result from harmful use of
              alcohol. This represents 5.3% of all deaths (source: WHO). The C
              Three Foundation is changing the conversation about alcohol use,
              misuse, and treatment. It&apos;s time to change the things we can
              no longer accept, using science and compassion. Please donate
              today.
            </div>
            <div css={[styles.donateButtonWrapper, styles.center]}>
              <a
                css={styles.donateButton}
                href="https://secure.givelively.org/donate/c-three-foundation"
                target="_blank"
                rel="noreferrer"
                role="button"
              >
                <span>Donate</span>
              </a>
            </div>
          </div>
        ),
      },
      {
        key: "/c3-10-page-content-block",
        content: (
          <div css={styles.center}>
            <div css={styles.contentTitle}>How I Overcame Alcoholism</div>
            <div css={styles.contentSubTitle}>
              Claudia Christian | TEDx London Business School | April 29, 2016
            </div>
            <div css={styles.contentWrapper}>
              <div css={styles.contentBox}>
                <div css={styles.contentYoutubeWrapper}>
                  <div css={styles.contentYoutubeBox}>
                    <iframe
                      css={styles.contentYoutube}
                      src="https://www.youtube.com/embed/6EghiY_s2ts"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ],
  };

  return (
    <Fragment>
      <Head
        title="C Three Foundation - The Official Charity of the Dark Universes"
        url="https://www.dark-universes.com/c-three-foundation"
        description="The C Three Foundation is the world's only nonprofit organization dedicated to raising awareness of the Sinclair Method for treating and preventing alcohol use disorder."
      />
      <CarouselComponent items={carouselItems} autoplay />
      <PageContent {...pageContent1} />
      <PageContent {...pageContent2} />
      <SocialBar />
    </Fragment>
  );
};

export default CThreeAbout;
