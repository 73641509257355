import React, { Fragment, ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import styles from "./styles";

export interface IPageContentBlock {
  key: string;
  title?: string | ReactNode;
  content: string | ReactNode;
  customStyles?: SerializedStyles;
}

export interface IPageContentProp {
  title: string | ReactNode;
  items: Array<IPageContentBlock>;
  customStyles?: SerializedStyles;
  sideContent?: string | ReactNode;
}

export const QuoteContent = (
  text: string,
  quoteBy?: string,
  style?: SerializedStyles | Array<SerializedStyles>
): ReactNode => {
  return (
    <span css={[styles.pageContentBlockQuote, styles.pageContentItalic, style]}>
      &quot;{text}&quot;
      {quoteBy ? (
        <span css={[styles.pageContentBlockQuoteBy]}> - {quoteBy}</span>
      ) : (
        <Fragment />
      )}
    </span>
  );
};

export const InlineQuoteContent = (
  text: string,
  style?: SerializedStyles | Array<SerializedStyles>
): ReactNode => {
  return (
    <span
      css={[
        styles.pageContentBlockInlineQuote,
        styles.pageContentItalic,
        style,
      ]}
    >
      &quot;{text}&quot;
    </span>
  );
};

export const InlineEmphasisContent = (
  text: string,
  style?: SerializedStyles | Array<SerializedStyles>
): ReactNode => {
  return (
    <span
      css={[
        styles.pageContentBlockInlineEmphasis,
        styles.pageContentItalic,
        style,
      ]}
    >
      {text}
    </span>
  );
};

const PageContent = ({
  title,
  items,
  customStyles,
  sideContent,
}: IPageContentProp): ReactElement => {
  return (
    <div css={[styles.pageContentWrapper, customStyles]}>
      <div css={styles.pageContent}>
        <div
          className="page-content-text-section"
          css={styles.pageContentTextSection}
        >
          {title ? (
            <div css={styles.pageContentHeading}>{title}</div>
          ) : (
            <Fragment />
          )}
          {items.map(
            ({
              key,
              title: itemTitle,
              content: itemContent,
              customStyles: itemCustomStyles,
            }: IPageContentBlock): ReactElement => {
              return (
                <div
                  key={key}
                  css={[styles.pageContentBlock, itemCustomStyles]}
                >
                  {itemTitle ? (
                    <div css={styles.pageContentBlockHeading}>{itemTitle}</div>
                  ) : (
                    <Fragment />
                  )}
                  <div css={styles.pageContentBlockContent}>{itemContent}</div>
                </div>
              );
            }
          )}
        </div>
        {sideContent ? (
          <div css={styles.pageContentSideSection}>{sideContent}</div>
        ) : (
          <Fragment />
        )}
      </div>
    </div>
  );
};

export default PageContent;
