import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "../../../utils/media-queries";

const sharedText = `
  position: absolute;
  width: 100%;
  padding: 0px 20px;
  
`;

export default {
  container: css`
    position: relative;
    text-align: center;
    color: ${theme.colors.white};
    font-size: 28px;
    line-height: 34px;
    width: 100%;
    height: 100%;

    @media ${queries.small} {
      font-size: 32px;
      line-height: 40px;
    }
  `,

  bottomLeft: css`
    ${sharedText}
    bottom: 8px;
    left: 16px;
  `,

  topLeft: css`
    ${sharedText}
    top: 8px;
    left: 16px;
  `,

  topRight: css`
    ${sharedText}
    top: 8px;
    right: 16px;
  `,

  bottomRight: css`
    ${sharedText}
    bottom: 8px;
    right: 16px;
  `,

  centered: css`
    ${sharedText}
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
};
