import { SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";

const Image = (
  alt: string,
  image: string,
  style?: SerializedStyles | Array<SerializedStyles>
): ReactNode => {
  return <img css={style} alt={alt} src={image} />;
};

export default Image;
