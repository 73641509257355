import React, { Fragment, ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import Image from "src/components/image";
import CarouselComponent, { ICarouselItem } from "src/components/carousel";
import PageContent, {
  IPageContentProp,
  QuoteContent,
} from "src/components/page-content";
import SocialBar from "src/components/social-bar";
import BubbleImage from "src/app/assets/images/bubble.jpg";
import DarkLegendsCoverImage from "src/app/assets/images/dark-legends-cover.jpg";
// import ClaudiaChristianHeadShotImage from "src/app/assets/images/claudia-christian-headshot-suit-side.jpg";
import ClaudiaChristianHeadShotImage from "src/app/assets/images/claudia-christian-headshot-blue-front.png";
import Head from "src/components/head";
import styles from "./styles";

const DarkLegends = (): ReactElement => {
  const carouselItems: Array<ICarouselItem> = [
    {
      key: "/dark-legends-cover-top-carousel",
      content: Image("Dark Legends", DarkLegendsCoverImage, [
        styles.cover,
        styles.centerDLCoverTop,
      ]),
    },
    {
      key: "/dark-legends-cover-bottom-carousel",
      content: Image("Dark Legends", DarkLegendsCoverImage, [
        styles.cover,
        styles.centerDLCoverBottom,
      ]),
    },
    {
      key: "/bubble-carousel",
      content: Image("Dark Legends", BubbleImage, styles.cover),
    },
  ];

  const pageContent: IPageContentProp = {
    title: "Dark Legends",
    items: [
      {
        key: "/dark-legends-1-page-content-block",
        content:
          "Dark Legends is a fantasy range created by Chris McAuley and Claudia Christian. It features characters based on a range of mythologies from Celtic to Norse. Worlds fought over by Gods in an interconnected universe. Reptilian hybrids rise from their slumber in the deep waters to cause havoc, Ice Giants defend their lands from demonic invaders and an Irish warrior knight wanders the land searching for vengeance.",
      },
      {
        key: "/dark-legends-2-page-content-block",
        content: QuoteContent(
          "Fantasy is a genre which is close to the hearts of both myself and Claudia. When we branched out into Science Fiction together it was only natural that we would do so with fantasy as well. We have crafted a tremendously open universe which draws on some mythological elements and a focus on demonically wicked monsters.",
          "Chris McAuley"
        ),
      },
      {
        key: "/dark-legends-3-page-content-block",
        content:
          "Dark Legends is being kicked off in Q2 of 2022 with the introduction of a warrior who attempts to resurrect his daughter. This leads to disastrous consequences and several bloody and bitter battles.",
      },
    ],
    sideContent: Image(
      "Claudia Christian",
      ClaudiaChristianHeadShotImage,
      styles.fill
    ),
  };

  return (
    <Fragment>
      <Head
        title="Dark Legends"
        url="https://www.dark-universes.com/dark-legends"
        description="Dark Legends - a fantasy range created by Chris McAuley and Claudia Christian featureing characters based on a range of mythologies from Celtic to Norse. Worlds fought over by Gods, with Reptilian hybrids rising from their slumber in the deep waters to cause havoc, Ice Giants defending their lands from demonic invaders and an Irish warrior knight who wanders the land searching for vengeance."
      />
      <CarouselComponent items={carouselItems} autoplay />
      <PageContent {...pageContent} />
      <SocialBar />
    </Fragment>
  );
};

export default DarkLegends;
