import { css } from "@emotion/react";
import theme from "src/theme";
// import { queries } from "../../../../utils/media-queries";

export default {
  cards: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1380px;

    .ant-list {
      max-width: 1280px;
    }
  `,
  cardsWrapper: css`
    display: flex;
    flex-flow: column;
    align-items: center;
  `,
  pagination: css`
    margin-bottom: 20px;

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border-radius: 8px;
    }

    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: ${theme.colors.functional.red};
      color: ${theme.colors.functional.red};
    }

    .ant-pagination-item a,
    .ant-pagination-item-active a {
      color: #191716;
      text-decoration: none;

      :hover {
        text-decoration: none;
      }
    }

    .ant-pagination-item {
      border-radius: 8px;

      :hover {
        border-color: ${theme.colors.functional.red};
      }

      a {
        :hover {
          color: ${theme.colors.functional.red};
        }
      }
    }

    .ant-pagination-item-active {
      border-color: ${theme.colors.functional.red};

      a {
        :hover {
          color: #191716;
        }
      }
    }
  `,
  cardWrapper: css`
    padding-bottom: 20px;
    height: 100%;
  `,
  card: css`
    min-width: 0;
    max-width: 400px;
    margin: 20px !important;
    box-shadow: none;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    background: ${theme.colors.white};

    :hover {
      transform: translateY(-0.25rem);
      box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
        0 5px 12px 4px rgb(0 0 0 / 9%);
    }

    .ant-card {
      box-shadow: unset;
      background: unset;
      padding: 0 !important;
    }

    .ant-card-head {
      align-items: unset;
      padding: 1.5rem 2rem !important;
    }

    .ant-card-head-title {
      overflow: unset;
      white-space: unset;
      text-overflow: unset;
      text-align: left;
    }

    .ant-card-body {
      padding: 0rem 2rem 1.5rem 2rem !important;
    }
  `,
  cardImage: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .lazyload-wrapper {
      display: flex;
      flex-grow: 1;
    }
  `,
};
