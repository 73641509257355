import { SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";
import LazyLoad from "react-lazyload";
import Image from "src/components/image";

const defaultOffset = [300, 300];

const LazyImage = (
  alt: string,
  image: string,
  imageStyle?: SerializedStyles | Array<SerializedStyles>,
  wrapperStyle?: SerializedStyles | Array<SerializedStyles>,
  once?: boolean
): ReactNode => {
  return (
    <LazyLoad css={wrapperStyle} once={once ?? true} offset={defaultOffset}>
      {Image(alt, image, imageStyle)}
    </LazyLoad>
  );
};

export default LazyImage;
