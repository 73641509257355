import React, { ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
// import { useLocation, NavLink } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { Carousel } from "antd";
// import { DashboardIcon } from "src/app/assets/icons";
import styles from "./styles";

// import { Layout, Menu, Tooltip } from "antd";
// import { Menu, Tooltip } from "antd";
// import { DashboardIcon } from "src/app/assets/icons";
// import { INavProp } from "src/components/navigation";

export interface ICarouselItem {
  key: string;
  content: ReactNode;
  customStyles?: SerializedStyles;
}

export interface ICarouselProp {
  items: Array<ICarouselItem>;
  autoplay?: boolean;
  autoplaySpeed?: number;
}

// const onChange = (a: number) => {
//   console.log(a);
// };

const CarouselComponent = ({
  items,
  autoplay = false,
  autoplaySpeed = 10000,
}: ICarouselProp): ReactElement => {
  // const { t } = useTranslation();

  return (
    <div css={styles.carousel}>
      <Carousel
        // afterChange={onChange}
        autoplay={autoplay}
        autoplaySpeed={autoplaySpeed}
      >
        {items.map(
          ({ key, content, customStyles }: ICarouselItem): ReactElement => {
            return (
              <div css={[styles.carouselItem, customStyles]} key={key}>
                <div css={styles.carouselContent}>{content}</div>
              </div>
            );
          }
        )}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
