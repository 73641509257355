import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { SerializedStyles } from "@emotion/react";
// import { useLocation, NavLink } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import { DashboardIcon } from "src/app/assets/icons";
import { Card, Pagination } from "antd";
import {
  IWindowCoordinates,
  scrollToCordinates,
} from "src/utils/use-scroll-to-top";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./styles";

// import { Layout, Menu, Tooltip } from "antd";
// import { DashboardIcon } from "src/app/assets/icons";
// import { INavProp } from "src/components/navigation";

export interface ICardDeckItem {
  key: string;
  title: string | ReactNode;
  content: string | ReactNode;
  image?: ReactNode;
  imageTop?: boolean;
  customItemStyles?: SerializedStyles | Array<SerializedStyles>;
  customImageStyles?: SerializedStyles | Array<SerializedStyles>;
  displayOrder?: number;
}

export interface ICardDeckProp {
  items: Array<ICardDeckItem>;
  defaultPageSize?: number;
  customStyles?: SerializedStyles;
}

const getInitialDeckState = (page: number, pageSize: number) => ({
  page,
  minValue: (page - 1) * pageSize,
  maxValue: page * pageSize,
});

const getScrollToCoordinates = (cardDeckRef: React.MutableRefObject<null>) => {
  const scrollCoordinates: IWindowCoordinates = {
    x: 0,
    y: 0,
  };

  if (cardDeckRef.current) {
    const cardDeckElem = cardDeckRef.current as HTMLElement;

    const hashElementBoundingRect = cardDeckElem.getBoundingClientRect();
    scrollCoordinates.y = hashElementBoundingRect.top + window.pageYOffset;
  }

  return scrollCoordinates;
};

const scrollToTop = (cardDeckRef: React.MutableRefObject<null>) => {
  const scrollCoordinates = getScrollToCoordinates(cardDeckRef);

  scrollToCordinates(scrollCoordinates);
};

const CardDeck = ({
  items,
  defaultPageSize,
  customStyles: customCardDeckStyles,
}: ICardDeckProp): ReactElement => {
  // const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const cardDeckRef = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const location = useLocation();

  const [showPagination] = useState(
    !(typeof defaultPageSize !== "undefined" && defaultPageSize <= 0)
  );
  const [pageSize] = useState(
    // eslint-disable-next-line no-nested-ternary
    typeof defaultPageSize !== "undefined"
      ? defaultPageSize > 0
        ? defaultPageSize
        : items.length
      : 6
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const queryParams = qs.parse(location.search);
  const initialDeckState = getInitialDeckState(
    queryParams.page ? Number(queryParams.page) : 1,
    pageSize
  );
  const [minValue, setMinValue] = useState(initialDeckState.minValue);
  const [maxValue, setMaxValue] = useState(initialDeckState.maxValue);
  const [currentPage, setCurrentPage] = useState(initialDeckState.page);
  const [pageChanged, setPageChanged] = useState(false);

  const setDeckState = (page: number) => {
    setCurrentPage(page);
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
    setPageChanged(true);
  };

  const handlePaginationChange = (page: number) => {
    setDeckState(page);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const existingQueryParams = qs.parse(location.search);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newQueries = { ...existingQueryParams, page };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    history.push({ search: qs.stringify(newQueries) });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Check to make sure that the last render has actually rendered the correct state.
    // This can be incorrect when using forward/back history buttons whereby the state
    // does not get updated as it would on refresh or pagination change event.
    const expectedCurrentPage = queryParams.page ? Number(queryParams.page) : 1;
    if (currentPage !== expectedCurrentPage) {
      setDeckState(expectedCurrentPage);
    } else if (pageChanged) {
      setPageChanged(false);
      scrollToTop(cardDeckRef);
    }
  });

  return (
    <div css={styles.cardsWrapper} ref={cardDeckRef}>
      <div css={[styles.cards, customCardDeckStyles]}>
        {items
          .slice(minValue, maxValue)
          .map(
            ({
              key,
              title,
              content,
              image,
              imageTop = true,
              customItemStyles: customCardDeckItemStyles,
              customImageStyles: customCardDeckImageStyles,
            }: ICardDeckItem): ReactElement => {
              return (
                <div key={key} css={[styles.card, customCardDeckItemStyles]}>
                  {imageTop ? (
                    <Fragment>
                      <div css={[styles.cardImage, customCardDeckImageStyles]}>
                        {image}
                      </div>
                      <Card title={title} bordered={false}>
                        {content}
                      </Card>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Card key={key} title={title} bordered={false}>
                        {content}
                      </Card>
                      <div css={[styles.cardImage, customCardDeckImageStyles]}>
                        {image}
                      </div>
                    </Fragment>
                  )}
                </div>
              );
            }
          )}
      </div>
      {showPagination ? (
        <div css={styles.pagination}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            onChange={handlePaginationChange}
            total={items.length} // total number of card data available
          />
        </div>
      ) : (
        <Fragment />
      )}
    </div>
  );
};

export default CardDeck;
