import React, { ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import styles from "./styles";

export interface ISocialBarProp {
  customStyles?: SerializedStyles;
}

export interface ISocialBarItem {
  key: string;
  to: string;
  isExternal?: boolean;
  title?: string;
  icon?: ReactNode;
  customStyles?: SerializedStyles;
}

const faIcon = (prop: IconProp) => (
  <FontAwesomeIcon css={styles.socialBarFAIcon} icon={prop} fixedWidth />
);

const SocialBar = ({ customStyles }: ISocialBarProp): ReactElement => {
  const items: Array<ISocialBarItem> = [
    {
      key: "/du-facebook-footer",
      to: "https://www.facebook.com/DarkUniverses",
      title: "Dark Universes",
      icon: faIcon(faFacebookF),
      isExternal: true,
    },
    {
      key: "/sv-facebook-footer",
      to: "https://www.facebook.com/stokerverse",
      title: "StokerVerse",
      icon: faIcon(faFacebookF),
      isExternal: true,
    },
    {
      key: "/dl-youtube-footer",
      to: "https://www.youtube.com/@ClaudiaChristiansDarkLegacies",
      isExternal: true,
      title: "Dark Legacies",
      icon: faIcon(faYoutube),
    },
    {
      key: "/sv-youtube-footer",
      to: "https://www.youtube.com/channel/UCVsqT53wFhNIrVdXTbnU2bA",
      isExternal: true,
      title: "StokerVerse",
      icon: faIcon(faYoutube),
    },
    {
      key: "/twitter-footer",
      to: "https://twitter.com/StokerMcAuley",
      title: "Twitter",
      icon: faIcon(faTwitter),
      isExternal: true,
    },
  ];

  return (
    <div css={[styles.socialBarWrapper, customStyles]}>
      <div css={styles.socialBar}>
        {items.map(
          ({
            icon,
            key,
            to,
            title: itemTitle,
            customStyles: itemCustomStyles,
            isExternal = true,
          }: ISocialBarItem): ReactElement => {
            return (
              <div css={itemCustomStyles} key={key}>
                {isExternal ? (
                  <a
                    css={styles.navLink}
                    href={to}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {icon}
                    {itemTitle}
                  </a>
                ) : (
                  <NavLink css={styles.navLink} to={to}>
                    {icon}
                    {itemTitle}
                  </NavLink>
                )}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default SocialBar;
