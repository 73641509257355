import React, { ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import { DashboardIcon } from "src/app/assets/icons";
import TopNavigationStyles from "src/components/top-navigation/styles";
import TopNavigation from "../top-navigation";
import config from "../../config";

export interface INavProp {
  key: string;
  to: string;
  dataTestId: string;
  title: string;
  icon: (isActive: boolean) => ReactNode;
  visible: boolean;
  customStyles: SerializedStyles;
}

export interface INavRouteProp {
  routeMatch: RegExp;
  titleTranslation: string;
}

const basePathname = config.basePathname === "/" ? "" : config.basePathname;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const navRoutes: Array<INavRouteProp> = [
  {
    routeMatch: /^\/$/i,
    titleTranslation: "",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/dark-legacies/?$`, "i"),
    titleTranslation: "Dark Legacies",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/dark-legends/?$`, "i"),
    titleTranslation: "Dark Legends",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/stokerverse/.+`, "i"),
    titleTranslation: "StokerVerse",
  },
  {
    routeMatch: new RegExp(`^${basePathname}/stokerverse/?$`, "i"),
    titleTranslation: "StokerVerse",
  },
];

const Navigation = (): ReactElement => {
  const navLinks: Array<INavProp> = [
    {
      key: "/home-nav-link",
      to: "/index.html",
      dataTestId: "home-nav-link",
      title: "Dark Universes",
      icon: (isActive) => (
        <DashboardIcon isActive={isActive} title="Dark Universes" />
      ),
      visible: true,
      customStyles: TopNavigationStyles.menuItem,
    },
    {
      key: "/dark-realms-nav-link",
      to: "/dark-realms",
      dataTestId: "dark-realms-nav-link",
      title: "Dark Realms",
      icon: (isActive) => (
        <DashboardIcon isActive={isActive} title="Dark Realms" />
      ),
      visible: true,
      customStyles: TopNavigationStyles.menuItem,
    },
    {
      key: "/dark-legacies-nav-link",
      to: "/dark-legacies",
      dataTestId: "dark-legacies-nav-link",
      title: "Dark Legacies",
      icon: (isActive) => (
        <DashboardIcon isActive={isActive} title="Dark Legacies" />
      ),
      visible: true,
      customStyles: TopNavigationStyles.menuItem,
    },
    {
      key: "/dark-legends-nav-link",
      to: "/dark-legends",
      dataTestId: "dark-legends-nav-link",
      title: "Dark Legends",
      icon: (isActive) => (
        <DashboardIcon isActive={isActive} title="Dark Legends" />
      ),
      visible: true,
      customStyles: TopNavigationStyles.menuItem,
    },
    {
      key: "/stokerverse-nav-link",
      to: "/stokerverse",
      dataTestId: "stokerverse-nav-link",
      title: "StokerVerse",
      icon: (isActive) => (
        <DashboardIcon isActive={isActive} title="StokerVerse" />
      ),
      visible: true,
      customStyles: TopNavigationStyles.menuItem,
    },
    {
      key: "/corporation-wars-nav-link",
      to: "/corporation-wars",
      dataTestId: "corporation-wars-nav-link",
      title: "Corporation Wars",
      icon: (isActive) => (
        <DashboardIcon
          isActive={isActive}
          title="Dark Legacies: Corporation Wars"
        />
      ),
      visible: true,
      customStyles: TopNavigationStyles.menuItem,
    },
    {
      key: "/c-three-foundation-nav-link",
      to: "/c-three-foundation",
      dataTestId: "c-three-foundation-nav-link",
      title: "Official Charity",
      icon: (isActive) => (
        <DashboardIcon isActive={isActive} title="C Three Foundation" />
      ),
      visible: true,
      customStyles: TopNavigationStyles.menuItem,
    },
  ];

  return <TopNavigation navLinks={navLinks} />;
};

export default Navigation;
