interface IQueries {
  extraSmall: string;
  small: string;
  medium: string;
  large: string;
  extraLarge: string;
  xxLarge: string;
  xxxLarge: string;
  xxxxLarge: string;
}

export const breakpoints = {
  extraSmall: 321,
  small: 576,
  medium: 768,
  large: 992,
  extraLarge: 1200,
  xxLarge: 1366,
  xxxLarge: 1536,
  xxxxLarge: 2000,
} as { [key: string]: number };

export const queries = Object.keys(breakpoints).reduce(
  (mapping, size) => ({
    ...mapping,
    [size]: `(min-width: ${breakpoints[size]}px)`,
  }),
  {}
) as IQueries;

export const customQuery = (size: number): string => `(min-width: ${size}px)`;
