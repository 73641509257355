import { SerializedStyles } from "@emotion/react";
import React, { ReactElement, ReactNode } from "react";
import Image from "src/components/image";
import styles from "./styles";

const ImageWithText = (
  alt: string,
  image: string,
  text: string,
  containerStyle?: SerializedStyles | Array<SerializedStyles>,
  imageStyle?: SerializedStyles | Array<SerializedStyles>,
  textStyle?: SerializedStyles | Array<SerializedStyles>
): ReactElement => {
  const imageNode: ReactNode = Image(alt, image, imageStyle);
  return (
    <div css={[styles.container, containerStyle]}>
      {imageNode}
      <div css={[styles.centered, textStyle]}>{text}</div>
    </div>
  );
};

export default ImageWithText;
