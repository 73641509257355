import { css } from "@emotion/react";
// import theme from "src/theme";
import { queries } from "../../../../utils/media-queries";

export default {
  cover: css`
    object-fit: cover;
  `,
  fill: css`
    object-fit: fill;
  `,
  contain: css`
    object-fit: contain;
  `,
  girlMask: css`
    object-position: center center;

    @media ${queries.small} {
      object-position: center -150px;
    }
    @media ${queries.medium} {
      object-position: center -220px;
    }
    @media ${queries.large} {
      object-position: center -320px;
    }
    @media ${queries.extraLarge} {
      object-position: center -420px;
    }
    @media ${queries.xxLarge} {
      object-position: center -470px;
    }
    @media ${queries.xxxLarge} {
      object-position: center -530px;
    }
  `,
  twoPeople: css`
    object-position: center center;

    @media ${queries.small} {
      object-position: center -80px;
    }
    @media ${queries.medium} {
      object-position: center -100px;
    }
    @media ${queries.large} {
      object-position: center -150px;
    }
    @media ${queries.extraLarge} {
      object-position: center -240px;
    }
    @media ${queries.xxLarge} {
      object-position: center -300px;
    }
    @media ${queries.xxxLarge} {
      object-position: center -340px;
    }
  `,
  womanTouchingScreen: css`
    object-position: center bottom;

    @media ${queries.small} {
      object-position: center -320px;
    }
    @media ${queries.medium} {
      object-position: center -400px;
    }
    @media ${queries.large} {
      object-position: center -550px;
    }
    @media ${queries.extraLarge} {
      object-position: center -650px;
    }
    @media ${queries.xxLarge} {
      object-position: center -720px;
    }
    @media ${queries.xxxLarge} {
      object-position: center center;
    }
  `,
  womanScreen: css`
    object-position: center center;

    @media ${queries.small} {
      object-position: center -80px;
    }
    @media ${queries.medium} {
      object-position: center -150px;
    }
    @media ${queries.large} {
      object-position: center -240px;
    }
    @media ${queries.extraLarge} {
      object-position: center -320px;
    }
    @media ${queries.xxLarge} {
      object-position: center -400px;
    }
    @media ${queries.xxxLarge} {
      object-position: center -500px;
    }
  `,
  centerDLCoverBottom: css`
    object-position: center bottom;
    @media ${queries.extraSmall} {
      object-position: center -250px;
    }
  `,
  containLogoImage: css`
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-top: 1rem;
  `,
  containLogoImageBottom: css`
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-bottom: 1rem;
  `,
};
