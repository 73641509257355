import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "../../../../utils/media-queries";

export default {
  cover: css`
    object-fit: cover;
  `,
  centerChrisDacre: css`
    object-position: left center;

    @media ${queries.small} {
      object-fit: cover;
      object-position: unset;
    }
  `,
  centerTopDracula: css`
    object-position: center top;

    @media ${queries.small} {
      object-position: center -50px;
    }

    @media ${queries.medium} {
      object-position: center -100px;
    }

    @media ${queries.large} {
      object-position: center -140px;
    }

    @media ${queries.extraLarge} {
      object-position: center -180px;
    }

    @media ${queries.xxLarge} {
      object-position: center -200px;
    }

    @media ${queries.xxxLarge} {
      object-position: center -220px;
    }
  `,
  centerNightCastle: css`
    object-position: center center;

    @media ${queries.medium} {
      object-position: center -20px;
    }

    @media ${queries.large} {
      object-position: center -70px;
    }

    @media ${queries.extraLarge} {
      object-position: center -120px;
    }

    @media ${queries.xxLarge} {
      object-position: center -150px;
    }

    @media ${queries.xxxLarge} {
      object-position: center -170px;
    }
  `,

  centerBatPotion: css`
    object-position: center right;
  `,

  centerDarkForest: css`
    object-position: center bottom;

    @media ${queries.medium} {
      object-position: center -500px;
    }

    @media ${queries.large} {
      object-position: center -750px;
    }

    @media ${queries.extraLarge} {
      object-position: center -900px;
    }

    @media ${queries.xxLarge} {
      object-position: center -1100px;
    }

    @media ${queries.xxxLarge} {
      object-position: center -1150px;
    }
  `,
  /*
  centerTopBeakMan: css`
    object-position: center top;

    @media ${queries.small} {
      object-position: center -50px;
    }

    @media ${queries.medium} {
      object-position: center -170px;
    }

    @media ${queries.large} {
      object-position: center -260px;
    }

    @media ${queries.extraLarge} {
      object-position: center -320px;
    }

    @media ${queries.xxLarge} {
      object-position: center -380px;
    }

    @media ${queries.xxxLarge} {
      object-position: center -440px;
    }
  `,
  centerTopHornedMan: css`
    object-position: center top;

    @media ${queries.extraSmall} {
      object-position: center -80px;
    }

    @media ${queries.small} {
      object-position: center -140px;
    }

    @media ${queries.medium} {
      object-position: center -280px;
    }

    @media ${queries.large} {
      object-position: center -420px;
    }

    @media ${queries.extraLarge} {
      object-position: center -550px;
    }

    @media ${queries.xxLarge} {
      object-position: center -620px;
    }

    @media ${queries.xxxLarge} {
      object-position: center -710px;
    }
  `,
  */
  fill: css`
    object-fit: fill;
  `,
  contain: css`
    object-fit: contain;
  `,
  containLogoImage: css`
    width: 100%;
    /*height: 150px;*/
    object-fit: contain;
    /*margin-top: 1rem;*/
    border-radius: 8px 8px 8px 8px;
  `,
  containLogoImageRight: css`
    width: 100%;
    /*height: 150px;*/
    object-fit: contain;
    /*margin-top: 1rem;*/
    border-radius: 0 8px 8px 0;
  `,
  cardDeck: css`
    display: flex;
    flex-direction: row;
    margin: 20px 0 20px 0 !important;
    /*margin-right: 20px !important;*/
  `,
  card: css`
    max-width: 100%;
    width: 100%;
    margin: 20px 0 20px 0 !important;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    /* display: grid;
    grid-auto-flow: column; */
    /* grid-auto-columns: 1fr; */

    .ant-card-head {
      /* padding-top: 0.25rem !important; */
    }

    .ant-card {
      min-width: 0px;
      width: 300px;
      max-width: 100%;
      flex-shrink: 1;
      flex-grow: 1;
    }
  `,
  cardRight: css`
    flex-wrap: wrap-reverse;
    align-items: flex-end;
  `,
  cardImage: css`
    max-width: 400px;
    flex-shrink: 1;
    flex-grow: 1;
    /* flex-shrink: 0; */
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .lazyload-wrapper {
      display: flex;
      flex-grow: 1;
    } */
  `,
  cardText: css`
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  `,
  cardTextAction: css`
    display: flex;
    justify-content: center;
    padding: 15px 0px 10px 0px;
  `,
  cardTextActionItem: css`
    display: flex;
    flex-grow: 1;
    justify-content: center;
  `,
  cardTextActionHeading: css`
    display: flex;
    justify-content: center;
    padding: 10px 0px 0px 0px;
    font-weight: bold;

    &:not(:last-child) {
      padding-bottom: 0;
    }
  `,
  cardTextActionHeadingItem: css`
    flex-grow: 1;
    text-align: center;
  `,
  cardDeckLinkButton: css`
    border: 1px solid ${theme.colors.black};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 0px 10px 0px 10px;

    a {
      color: ${theme.colors.primary};
    }

    :hover {
      color: ${theme.colors.functional.red};
      border-color: ${theme.colors.functional.red};

      a {
        color: ${theme.colors.functional.red};
      }
    }
  `,
  cardDeckLinkButtonDownload: css`
    padding: 0px 10px 0px 5px;
  `,
  cardDeckFAIcon: css`
    margin: 0 10px 0 0px;
    font-size: 24px;
  `,
  cardDeckNavLink: css`
    text-decoration: none !important;
    display: flex;
    align-items: center;
    line-height: 40px;
    border: 1px solid ${theme.colors.black};
    border-radius: 8px;
    padding: 0px 10px 0px 10px;
    margin: 0px 10px 0px 10px;

    :hover {
      color: ${theme.colors.functional.red};
      border-color: ${theme.colors.functional.red};
    }
  `,
  pageContent2Wrapper: css`
    margin-top: 0;

    .page-content-text-section {
      padding-top: 0;
    }
  `,
};
