import { css } from "@emotion/react";
// import theme from "src/theme";
// import { queries } from "../../../../utils/media-queries";

export default {
  cover: css`
    object-fit: cover;
  `,
  fill: css`
    object-fit: fill;
  `,
  contain: css`
    object-fit: contain;
  `,
  containLogoImage: css`
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-top: 1rem;
  `,
  containLogoImageBottom: css`
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-bottom: 1rem;
  `,
};
