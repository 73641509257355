import { css } from "@emotion/react";
import theme from "src/theme";
// import { queries } from "../../../../utils/media-queries";

export default {
  carousel: css``,
  carouselItem: css`
    background: ${theme.colors.primary.darkBlue};
    width: 100%;
    height: 400px;

    img {
      height: 100%;
      width: 100%;
    }
  `,
  carouselContent: css`
    background: ${theme.colors.black};
    height: 100%;
    width: 100%;
    color: #fff;
    line-height: 160px;
    text-align: center;
  `,
};
