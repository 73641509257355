/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/prefer-default-export
export const sortByProperty = <T extends Record<string, any>>(
  array: Array<T>,
  propName: string,
  ascending = true
): Array<T> =>
  ascending
    ? array.sort((a: T, b: T) => a[propName] - b[propName])
    : array.sort((a: T, b: T) => b[propName] - a[propName]);
