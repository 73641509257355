import React, { ReactElement } from "react";
import { Layout } from "antd";
import { Redirect, Route, BrowserRouter, Switch } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import Navigation from "src/components/navigation";
import FooterContent from "src/components/footer";
import { Header } from "antd/lib/layout/layout";
import ScrollToTop from "src/components/scroll-to-top";
import { HelmetProvider } from "react-helmet-async";
import Head from "src/components/head";
import styles from "./styles";
import Home from "./pages/home";
import DarkLegacies from "./pages/dark-legacies";
import StokerVerse from "./pages/stokerverse";
import DarkLegends from "./pages/dark-legends";
import CorporationWars from "./pages/corporation-wars";
import CThreeFoundation from "./pages/c-three-foundation";
import DarkRealms from "./pages/dark-realms";

function App(): ReactElement {
  // const { t } = useTranslation();

  const { Footer, Content } = Layout;

  return (
    <HelmetProvider>
      <Head
        title="Dark Universes"
        description="Home of the dark universes - Dark Realms, Dark Legacies, Dark Legends and the StokerVerse."
      />
      <div>
        <Layout>
          <BrowserRouter>
            <ScrollToTop />
            <Header css={styles.header}>
              <Navigation />
            </Header>
            <Content css={styles.pageContainer}>
              <div css={styles.page}>
                <Switch>
                  <Route exact path="/index.html">
                    <Redirect to="/" />
                  </Route>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/dark-realms">
                    <DarkRealms />
                  </Route>
                  <Route exact path="/dark-legacies">
                    <DarkLegacies />
                  </Route>
                  <Route exact path="/dark-legends">
                    <DarkLegends />
                  </Route>
                  <Route exact path="/stokerverse">
                    <StokerVerse />
                  </Route>
                  <Route exact path="/corporation-wars">
                    <CorporationWars />
                  </Route>
                  <Route exact path="/c-three-foundation">
                    <CThreeFoundation />
                  </Route>
                </Switch>
              </div>
            </Content>
            <Footer css={styles.footer}>
              <FooterContent />
            </Footer>
          </BrowserRouter>
        </Layout>
      </div>
    </HelmetProvider>
  );
}

export default App;
