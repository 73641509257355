import React, { Fragment, ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import Image from "src/components/image";
import CarouselComponent, { ICarouselItem } from "src/components/carousel";
import PageContent, {
  IPageContentProp,
  QuoteContent,
} from "src/components/page-content";
import SocialBar from "src/components/social-bar";
import SkullTimeImage from "src/app/assets/images/skull-time.jpg";
import CamouflageClownImage from "src/app/assets/images/camouflage-clown.jpg";
import ClownPuppetImage from "src/app/assets/images/clown-puppet.jpg";
import DarkClownImage from "src/app/assets/images/dark-clown.jpg";
import ForestBirdWomanImage from "src/app/assets/images/forest-bird-woman.jpg";
import GirlFearForestImage from "src/app/assets/images/girl-fear-forest.jpg";
import HorrorSkullsImage from "src/app/assets/images/horror-skulls.jpg";
import SkullBonesForestImage from "src/app/assets/images/skull-bones-forest.jpg";
import ChrisMcAuleyHeadshotImage from "src/app/assets/images/chris-mcauley-headshot.png";
import ImageWithText from "src/components/image-with-text";
import Head from "src/components/head";
import styles from "./styles";

const DarkRealms = (): ReactElement => {
  const carouselItems: Array<ICarouselItem> = [
    {
      key: "/skull-time-carousel",
      content: Image("Dark Realms", SkullTimeImage, [
        styles.cover,
        styles.skullTime,
      ]),
    },
    {
      key: "/dark-clown-carousel",
      content: ImageWithText(
        "Dark Realms",
        DarkClownImage,
        "Dark Realms",
        styles.darkOverlay,
        [styles.cover, styles.darkClown, styles.darkOverlayImage],
        styles.darkOverlayText
      ),
    },
    {
      key: "/girl-fear-forest-carousel",
      content: Image("Dark Realms", GirlFearForestImage, [
        styles.cover,
        styles.girlFearForest,
      ]),
    },
    {
      key: "/clown-puppet-carousel",
      content: Image("Dark Realms", ClownPuppetImage, [
        styles.cover,
        styles.clownPuppet,
      ]),
    },
    {
      key: "/horror-skulls-carousel",
      content: Image("Dark Realms", HorrorSkullsImage, [styles.cover]),
    },
    {
      key: "/camouflage-clown-carousel",
      content: Image("Dark Realms", CamouflageClownImage, [
        styles.cover,
        styles.camouflageClown,
      ]),
    },
    {
      key: "/skull-bones-forest-carousel",
      content: Image("Dark Realms", SkullBonesForestImage, styles.cover),
    },
    {
      key: "/forest-bird-woman-carousel",
      content: Image("Dark Realms", ForestBirdWomanImage, [
        styles.cover,
        styles.forestBirdWoman,
      ]),
    },
  ];

  const pageContent: IPageContentProp = {
    title: "Dark Realms",
    items: [
      {
        key: "/dark-realms-1-page-content-block",
        content:
          "Dark Realms is a Horror Universe like no other, where fear reigns and macabre is instinctive. Created by Chris McAuley it is a collection of dark worlds spanning the genres of horror, crime, mystery and thriller. Existing as a seemingly fractured yet deeply interconnected universe, Dark Realms will feature tales that explore the posibilities within the endless darkness and probe the depths of human existance.",
      },
      {
        key: "/dark-realms-2-page-content-block",
        content: QuoteContent(
          "As I listened, I felt myself encased in the environment of the story. He *Chris* has a natural voice for storytelling.",
          "Quentin Tarantino"
        ),
      },
      {
        key: "/dark-realms-3-page-content-block",
        content:
          "Through a series of novels, comic books, games, audio dramas and poetry, readers will be able to journey with us into the unknown darkness between worlds and experience the wonder and pain of the imagination.",
      },
      {
        key: "/dark-realms-4-page-content-block",
        content:
          "Dark Realms will feature stories from the acclaimed, award winning writer, artist and character creator Dr Chris McAuley as well as best selling horror authors Paul Kane, Denise Ciecin, John Peel and Joe Pasqualle.",
      },
    ],
    sideContent: Image("Chris McAuley", ChrisMcAuleyHeadshotImage, styles.fill),
  };

  return (
    <Fragment>
      <Head
        title="Dark Realms"
        url="https://www.dark-universes.com/dark-realms"
        description="Dark Realms - where fear reigns and macabre is instinctive. Created by Chris McAuley it is a collection of dark worlds spanning the genres of horror, crime, mystery and thriller."
      />
      <CarouselComponent items={carouselItems} autoplay />
      <PageContent {...pageContent} />
      <SocialBar />
    </Fragment>
  );
};

export default DarkRealms;
