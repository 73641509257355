import React, { Fragment, ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import Image from "src/components/image";
import CarouselComponent, { ICarouselItem } from "src/components/carousel";
import PageContent, { IPageContentProp } from "src/components/page-content";
import SocialBar from "src/components/social-bar";
import CyberPartyImage from "src/app/assets/images/cyber-party.jpg";
import CyberReflectionImage from "src/app/assets/images/cyber-reflection.jpg";
import CyberGirlMaskImage from "src/app/assets/images/cyber-girl-mask.jpg";
import CyberTwoPeopleImage from "src/app/assets/images/cyber-two-people.jpg";
import WomanTouchingScreenImage from "src/app/assets/images/woman-touching-screen.jpg";
import CyberManUnderBridgeImage from "src/app/assets/images/cyber-man-under-bridge.jpg";
import CyberWomanImage from "src/app/assets/images/cyber-woman.jpg";
import CyberBuildingImage from "src/app/assets/images/cyber-building.jpg";
import ElectricFloorImage from "src/app/assets/images/electric-floor.jpg";
import ChrisMcAuleyHeadshotImage from "src/app/assets/images/chris-mcauley-headshot.png";
import Head from "src/components/head";
import styles from "./styles";

const CorporationWars = (): ReactElement => {
  const carouselItems: Array<ICarouselItem> = [
    {
      key: "/cyberverse-electric-floor-carousel",
      content: Image(
        "Dark Legacies: Corporation Wars",
        ElectricFloorImage,
        styles.cover
      ),
    },
    {
      key: "/cyber-party-carousel",
      content: Image(
        "Dark Legacies: Corporation Wars",
        CyberPartyImage,
        styles.cover
      ),
    },
    {
      key: "/cyber-girl-mask-carousel",
      content: Image("Dark Legacies: Corporation Wars", CyberGirlMaskImage, [
        styles.cover,
        styles.girlMask,
      ]),
    },
    {
      key: "/cyber-woman-carousel",
      content: Image("Dark Legacies: Corporation Wars", CyberWomanImage, [
        styles.cover,
        styles.womanScreen,
      ]),
    },
    {
      key: "/cyber-building-carousel",
      content: Image(
        "Dark Legacies: Corporation Wars",
        CyberBuildingImage,
        styles.cover
      ),
    },
    {
      key: "/woman-touching-screen-carousel",
      content: Image(
        "Dark Legacies: Corporation Wars",
        WomanTouchingScreenImage,
        [styles.cover, styles.womanTouchingScreen]
      ),
    },
    {
      key: "/cyber-man-under-bridge-carousel",
      content: Image(
        "Dark Legacies: Corporation Wars",
        CyberManUnderBridgeImage,
        styles.cover
      ),
    },
    {
      key: "/cyber-two-people-carousel",
      content: Image("Dark Legacies: Corporation Wars", CyberTwoPeopleImage, [
        styles.cover,
        styles.twoPeople,
      ]),
    },
    {
      key: "/cyber-reflection-carousel",
      content: Image(
        "Dark Legacies: Corporation Wars",
        CyberReflectionImage,
        styles.cover
      ),
    },
  ];

  const pageContent: IPageContentProp = {
    title: "Dark Legacies: Corporation Wars",
    items: [
      {
        key: "/corporation-wars-1-page-content-block",
        content:
          "Dark Legacies: Corporation Wars, is a fast paced, dark science fiction universe created by Chris McAuley, featuring advanced science and technology set in an urban, dystopian future. It showcases a world where the rise in corporate power mixed with advancement in technology, cybernetics and artificial intelligence has resulted in a future of societal collapse and decay, where alienation and countercultural antiheroes are trapped in a dehumanized, high-tech existence.",
      },
      {
        key: "/corporation-wars-2-page-content-block",
        content:
          "Watch this space for more content coming soon in Dark Legacies: Corporation Wars!",
      },
    ],
    sideContent: Image("Chris McAuley", ChrisMcAuleyHeadshotImage, styles.fill),
  };

  return (
    <Fragment>
      <Head
        title="Dark Legacies: Corporation Wars"
        url="https://www.dark-universes.com/corporation-wars"
        description="Dark Legacies: Corporation Wars - A dystopian future where the rise in corporate power mixed with advancement in technology, cybernetics and artificial intelligence has resulted in a future of societal collapse and decay."
      />
      <CarouselComponent items={carouselItems} autoplay />
      <PageContent {...pageContent} />
      <SocialBar />
    </Fragment>
  );
};

export default CorporationWars;
