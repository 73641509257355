import React from "react";
import i18n from "i18next";
import moment from "moment";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";

const withInternationalization = (
  Component: React.FunctionComponent
): React.FunctionComponent => {
  moment.locale("en");

  void i18n.use(initReactI18next).init({
    resources: { en: { translation: { ...en } } },
    lng: "en",
    keySeparator: "##",
    interpolation: {
      escapeValue: false,
      format(value, format) {
        if (value instanceof Date) return moment(value).format(format);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return value;
      },
    },
    saveMissing: true,
    missingKeyHandler: (...args: Array<unknown>) => {
      // eslint-disable-next-line no-console
      console.error("Missing translation key", args);
    },
  });

  return (props: Record<string, unknown>) => (
    <ConfigProvider locale={enUS}>
      <Component {...props} />
    </ConfigProvider>
  );
};

export default withInternationalization;
