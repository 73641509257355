import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "../../../../utils/media-queries";

export default {
  cover: css`
    object-fit: cover;
  `,
  fill: css`
    object-fit: fill;
  `,
  contain: css`
    object-fit: contain;
  `,
  containLogoImage: css`
    width: 100%;
    /*height: 150px;*/
    object-fit: contain;
    /*margin-top: 1rem;*/
    border-radius: 8px 8px 0 0;
  `,
  containVideoThumbnailImage: css`
    .react-player__preview {
      height: 0;
      padding-top: 56.75%;

      .react-player__shadow {
        margin-top: -56.75%;
      }
    }
  `,
  stokerVerseLogoImage: css`
    margin: 0;
    padding: 2rem 2rem 0rem 2rem;
    /*height: 86px;*/
  `,
  containLogoImageBottom: css`
    width: 100%;
    /*height: 150px;*/
    object-fit: contain;
    /*margin-bottom: 1rem;*/
    border-radius: 0 0 8px 8px;
  `,
  card: css`
    max-width: 400px;
    /*margin-right: 20px !important;*/
  `,
  cardText: css`
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  `,
  cardTextAction: css`
    display: flex;
    justify-content: center;
    padding: 15px 0px 10px 0px;
  `,
  cardTextActionItem: css`
    display: flex;
    flex-grow: 1;
    justify-content: center;
  `,
  cardTextActionHeading: css`
    display: flex;
    justify-content: center;
    padding: 10px 0px 0px 0px;
    font-weight: bold;

    &:not(:last-child) {
      padding-bottom: 0;
    }
  `,
  cardTextActionHeadingItem: css`
    flex-grow: 1;
    text-align: center;
  `,
  darkUniversesBannerLandscape: css`
    object-position: center center;

    @media ${queries.medium} {
      object-position: center 95%;
    }
  `,
  darkUniversesBannerPortrait: css`
    object-position: center center;

    @media ${queries.small} {
      object-position: center 60%;
    }
    @media ${queries.medium} {
      object-position: center 72%;
      object-position: center top;
    }
    @media ${queries.large} {
      object-position: center 14%;
    }
    @media ${queries.extraLarge} {
      object-position: center 19%;
    }
    @media ${queries.xxLarge} {
      object-position: center 20%;
    }
    @media ${queries.xxxLarge} {
      object-position: center 20%;
    }
    @media ${queries.xxxxLarge} {
      object-position: center top;
    }
  `,
  spiderBlood: css`
    object-position: center 90%;

    @media ${queries.small} {
      object-position: center 90%;
    }
  `,
  candleSkull: css`
    object-position: center 90%;

    @media ${queries.small} {
      object-position: center 65%;
    }
  `,
  cardDeckLinkButton: css`
    border: 1px solid ${theme.colors.black};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 0px 10px 0px 10px;

    a {
      color: ${theme.colors.primary};
    }

    :hover {
      color: ${theme.colors.functional.red};
      border-color: ${theme.colors.functional.red};

      a {
        color: ${theme.colors.functional.red};
      }
    }
  `,
  cardDeckLinkButtonDownload: css`
    padding: 0px 10px 0px 5px;
  `,
  cardDeckLinkButtonExternalWebsite: css`
    padding: 0px 10px 0px 5px;
  `,
  cardDeckFAIcon: css`
    margin: 0 10px 0 0px;
    font-size: 24px;
  `,
  cardDeckNavLink: css`
    text-decoration: none !important;
    display: flex;
    align-items: center;
    line-height: 40px;
    border: 1px solid ${theme.colors.black};
    border-radius: 8px;
    padding: 0px 10px 0px 10px;
    margin: 0px 10px 0px 10px;

    :hover {
      color: ${theme.colors.functional.red};
      border-color: ${theme.colors.functional.red};
    }
  `,
};
