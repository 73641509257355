import { SerializedStyles } from "@emotion/react";
import React, { ReactNode, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";

export interface IVideoPlayerProp {
  url: string;
  style?: SerializedStyles | Array<SerializedStyles>;
  playing?: boolean;
  light?: string | boolean;
}

const VideoPlayer = ({
  url,
  style,
  playing = false,
  light = false,
}: IVideoPlayerProp): ReactNode => {
  const playerRef = useRef(null);
  const [playingState, setPlayingState] = useState(playing);
  const [lightState] = useState(light);

  const enableDownload = false;
  const enableRemotePlayback = false;
  const enableFullscreen = true;
  const playerControlListArr = [];

  if (!enableDownload) playerControlListArr.push("nodownload");
  if (!enableRemotePlayback) playerControlListArr.push("noremoteplayback");
  if (!enableFullscreen) playerControlListArr.push("nofullscreen");

  const playerControlList = playerControlListArr.join(" ");

  return (
    <ReactPlayer
      ref={playerRef}
      css={style}
      width="100%"
      height="100%"
      url={url}
      light={lightState}
      controls
      playing={playingState}
      playsinline
      onPlay={() => setPlayingState(true)}
      onPause={() => setPlayingState(false)}
      onClickPreview={() => {
        if (lightState) {
          setPlayingState(true);
        }
      }}
      config={{
        file: {
          attributes: {
            controlsList: playerControlList,
          },
        },
      }}
    />
  );
};

export default VideoPlayer;
