import { css } from "@emotion/react";
import theme from "src/theme";
import { queries, customQuery } from "../../../utils/media-queries";

export default {
  footer: css`
    background: transparent;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  `,
  footerSection: css`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;

    &:first-of-type {
      margin-bottom: 60px;
    }

    @media ${queries.medium} {
      &:first-of-type {
        margin-bottom: 20px;
      }
    }
  `,
  footerLogoSection: css`
    align-items: center;
    max-width: 255px;
  `,
  footerHeading: css`
    font-weight: 600;
    color: ${theme.colors.white};
    margin-bottom: 5px;
    font-size: 18px;
    text-align: center;

    /* Changeover point when Footer Sections wrap to 3 rows */
    @media ${customQuery(723)} {
      text-align: left;
    }
  `,
  footerLogoHeading: css`
    font-weight: 600;
    font-size: 2rem;
    color: ${theme.colors.white};
    line-height: 38px;
  `,
  footerText: css`
    font-weight: 600;
    color: ${theme.colors.white};
    padding-top: 46px;
    text-align: center;
  `,
  footerLogoTextCopyright: css`
    display: block;

    @media ${customQuery(420)} {
      display: inline-block;
      padding-right: 10px;
    }
  `,
  footerLogoTextARR: css`
    display: block;

    @media ${customQuery(420)} {
      display: inline-block;
    }
  `,
  footerItem: css``,
  navLinkFAIcon: css`
    padding-right: 10px;
    font-size: 24px;
  `,
  navLink: css`
    color: ${theme.colors.white} !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    line-height: 40px;

    .dashboardIcon,
    .dashboardIconInactive,
    .iconFill {
      fill: ${theme.colors.white};
    }
    .iconStroke {
      stroke: ${theme.colors.white};
      stroke-width: 1.5;
    }

    :hover {
      color: ${theme.colors.functional.red} !important;
      text-decoration: none !important;
      /*
      .iconFill {
        fill: ${theme.colors.functional.red} !important;
      }
      .iconStroke {
        stroke: ${theme.colors.functional.red} !important;
      }
      */
      .dashboardIcon,
      .dashboardIconInactive {
        fill: ${theme.colors.functional.red} !important;
      }
    }

    .iconFillSelected {
      fill: ${theme.colors.functional.red} !important;
    }

    .iconStrokeSelected {
      stroke: ${theme.colors.secondary.cyan} !important;
    }
  `,
  duLogoImage: css`
    width: 200px;
    /*height: 150px;*/
    object-fit: contain;
    /*margin-top: 1rem;*/
    /*border-radius: 8px 8px 0 0;*/
  `,
};
