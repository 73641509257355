const colors = {
  primary: {
    blue: "var(--colors-primary-blue)",
    darkBlue: "var(--colors-primary-dark-blue)",
    black: "var(--colors-primary-black)",
  },
  secondary: {
    darkBlue: "var(--colors-secondary-dark-blue)",
    lightBlue: "var(--colors-secondary-light-blue)",
    navyBlue: "var(--colors-secondary-navy-blue)",
    purple: "var(--colors-secondary-purple)",
    lightPurple: "var(--colors-secondary-light-purple)",
    darkPurple: "var(--colors-secondary-dark-purple)",
    black: "var(--colors-secondary-black)",
    cyan: "var(--colors-secondary-cyan)",
    darkGray: "var(--colors-dark-gray)",
    lightGray: "var(--colors-light-gray)",
  },
  background: {
    white: "var(--colors-white)",
    veryLightGray: "var(--colors-very-light-gray)",
    lightGray: "var(--colors-light-gray)",
    darkGray: "var(--colors-dark-gray)",
    black: "var(--colors-black)",
    gray: "var(--colors-background-gray)",
    gray2: "var(--colors-background-gray2)",
  },
  functional: {
    red: "var(--colors-functional-red)",
    blue: "var(--colors-functional-blue)",
    orange: "var(--colors-functional-orange)",
    green: "var(--colors-functional-green)",
  },
  white: "var(--colors-white)",
  black: "var(--colors-black)",
};

const svgSize = {
  small: 16,
  medium: 24,
  large: 38,
  xlarge: 48,
  nav: 56,
};

const fonts = {
  proximaNova: "proxima-nova, sans-serif",
  calluna: "calluna, serif",
};

const zIndex = {
  header: "100",
  headerButtons: "1051",
};

const typography = {
  proximaNova: {
    body: {
      fontWeight: "regular",
      fontSize: "1rem",
      lineHeight: "1.375rem",
      color: "var(--colors-black)",
    },
    small: {
      fontWeight: "regular",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "var(--colors-black)",
    },
    tableHeader: {
      fontWeight: "semibold",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      color: "var(--colors-black)",
    },
    input: {
      fontWeight: "regular",
      fontSize: "1rem",
      lineHeight: "1.375rem",
      color: "var(--colors-black)",
    },
    label: {
      fontWeight: "regular",
      fontSize: "0.75rem",
      color: "var(--colors-dark-gray)",
    },
    link: {
      fontWeight: "bold",
      color: "#1F48E7",
    },
    header1: {
      desktop: {
        fontWeight: "bold",
        fontSize: "2rem",
        lineHeight: "2.375rem",
        color: "var(--colors-black)",
      },
      mobile: {
        fontWeight: "bold",
        fontSize: "1.75rem",
        lineHeight: "2.125rem",
        color: "var(--colors-black)",
      },
    },
    header2: {
      desktop: {
        fontWeight: "semibold",
        fontSize: "1.5rem",
        lineHeight: "1.875rem",
        color: "var(--colors-secondary-navy-blue)",
      },
      mobile: {
        fontWeight: "semibold",
        fontSize: "1.25rem",
        lineHeight: "1.625rem",
        color: "var(--colors-secondary-navy-blue)",
      },
    },
    header3: {
      desktop: {
        fontWeight: "regular",
        fontSize: "1.25rem",
        lineHeight: "1.625rem",
        color: "var(--colors-black)",
      },
      mobile: {
        fontWeight: "regular",
        fontSize: "1.125rem",
        lineHeight: "1.5rem",
        color: "var(--colors-black)",
      },
    },
  },
  calluna: {
    header1: {
      desktop: {
        fontWeight: "bold",
        fontSize: "3rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      tablet: {
        fontWeight: "bold",
        fontSize: "2.5rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      mobile: {
        fontWeight: "bold",
        fontSize: "2.25rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
    },
    header2: {
      desktop: {
        fontWeight: "bold",
        fontSize: "2.75rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      tablet: {
        fontWeight: "bold",
        fontSize: "2.75rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      mobile: {
        fontWeight: "semibold",
        fontSize: "1.75rem",
        lineHeight: "1.625rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
    },
    header3: {
      desktop: {
        fontWeight: "bold",
        fontSize: "2.25rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      tablet: {
        fontWeight: "bold",
        fontSize: "1.75rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      mobile: {
        fontWeight: "semibold",
        fontSize: "1.375rem",
        lineHeight: "1.625rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
    },
  },
};

export default {
  colors,
  svgSize,
  fonts,
  typography,
  zIndex,
};
