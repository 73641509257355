// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, ReactElement } from "react";
import { Helmet } from "react-helmet-async";

export interface IHeadProp {
  title?: string;
  description?: string;
  url?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Head = ({ title, description, url }: IHeadProp): ReactElement => {
  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        {/* <!-- facebook meta --> */}
        <meta property="og:site_name" content="Dark Universes" />

        {/* <!-- twitter meta --> */}
        <meta name="twitter:card" content="summary" />

        {/* <!-- google plus meta --> */}
      </Helmet>

      {/* <!-- google meta --> */}
      {title ? (
        <Helmet prioritizeSeoTags>
          <title>{title}</title>
          <meta property="og:title" content={`${title}`} />
          <meta itemProp="name" content={`${title}`} />
        </Helmet>
      ) : (
        <Helmet />
      )}

      {description ? (
        <Helmet prioritizeSeoTags>
          <meta name="description" content={`${description}`} />
          <meta property="og:description" content={`${description}`} />
          <meta name="twitter:description" content={`${description}`} />
          <meta itemProp="description" content={`${description}`} />
        </Helmet>
      ) : (
        <Helmet />
      )}

      {url ? (
        <Helmet prioritizeSeoTags>
          <meta name="twitter:url" content={`${url}`} />
          <meta property="og:url" content={`${url}`} />
        </Helmet>
      ) : (
        <Helmet />
      )}
    </Fragment>
  );
};

export default Head;
