import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "../../utils/media-queries";
import "../../theme/index.css";

export default {
  app: css`
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    @media ${queries.large} {
      background: transparent;
      padding-bottom: 0;
    }
  `,
  navContainer: css`
    margin-top: 3rem;
    width: 100vh;

    @media ${queries.large} {
      margin-top: 0;
    }
  `,
  pageContainer: css`
    @media ${queries.large} {
      margin-top: 0;
    }
    background: ${theme.colors.background.gray2} !important;
    flex-grow: 1;
  `,
  page: css`
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
  header: css`
    @media ${queries.large} {
      margin-top: 0;
    }
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    background: ${theme.colors.black};
  `,
  footer: css`
    @media ${queries.large} {
      margin-top: 0;
    }
    background: ${theme.colors.black};
    display: flex;
    justify-content: center;
    flex-flow: wrap;
  `,
};
